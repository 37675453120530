import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Card,
  CardContent,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useForm, Controller } from 'react-hook-form';
import { PaymentType } from 'interfaces';
import paymentTypesService from 'services/paymentTypesService';
import { CustomerId } from 'interfaces/customersId.interface';
import useUser from 'hooks/useUser';

type StateForm = {
  idCliente: string;
  paymentTypeCode: number;
};

type Props = {
  setCustomerIds: (data: CustomerId[]) => void;
  customerIds: CustomerId[];
};

const CustomerIds: React.FC<Props> = ({ customerIds, setCustomerIds }) => {
  const { register, errors, control, handleSubmit, setValue, setError } = useForm<StateForm>();
  const { getPaymentTypes } = paymentTypesService();
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const { jwt } = useUser();

  useEffect(() => {
    if (jwt) {
      // consumir api para traer payment
      getPaymentTypes(jwt).then((result) => {
        setPaymentTypes(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const optionsPaymentTypesByUser = (paymentTypesOptions: PaymentType[]) => {
    return paymentTypesOptions.map((paymentTypeOption) => {
      return (
        <option
          aria-label={paymentTypeOption.name}
          value={paymentTypeOption.value}
          key={`${paymentTypeOption.name}-`}
        >
          {paymentTypeOption.name}
        </option>
      );
    });
  };

  const onSubmit = (data: StateForm) => {
    if (
      customerIds.some(
        (c) => c.idCliente === Number(data.idCliente) && c.paymentTypeCode === data.paymentTypeCode
      )
    ) {
      setError('idCliente', { message: 'Id cliente ya existe' });
      setError('paymentTypeCode', { message: 'Modalidad de pago ya existe' });
      return;
    }

    const customerIdToAdd: CustomerId = {
      id: '',
      idCliente: Number(data.idCliente),
      paymentTypeCode: data.paymentTypeCode,
      paymentTypeName: paymentTypes.find((p) => p.value == data.paymentTypeCode)?.name || '',
    };
    setCustomerIds([...customerIds, customerIdToAdd]);
    setValue('idCliente', '');
    setValue('paymentTypeCode', 0);
  };

  const handleDeleteCustomerId = (customerId: CustomerId) => {
    const cTovalidate = `${customerId.idCliente}${customerId.paymentTypeCode}`;
    const newCustomersId = customerIds.filter(
      (c) => `${c.idCliente}${c.paymentTypeCode}` !== cTovalidate
    );
    setCustomerIds([...newCustomersId]);
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="dense"
              id="idCliente"
              label="Id cliente"
              name="idCliente"
              type="number"
              autoComplete="off"
              inputRef={register<HTMLInputElement>({
                required: 'Campo requerido',
              })}
              error={errors.idCliente ? true : false}
              helperText={errors.idCliente?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {/* paymentType 1 */}
          <Grid item xs={12} sm={5}>
            <FormControl fullWidth variant="outlined" error={errors.paymentTypeCode ? true : false}>
              <Controller
                name="paymentTypeCode"
                control={control}
                defaultValue={0}
                rules={{
                  required: 'Campo requerido',
                  validate: (value) => (value > 0 ? true : 'Campo Modalidad de pago no es válido'),
                }}
                as={
                  <TextField
                    fullWidth
                    select
                    margin="dense"
                    variant="outlined"
                    label="Selecciona modalidad de pago"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.paymentTypeCode ? true : false}
                    helperText={errors.paymentTypeCode?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="" />
                    {optionsPaymentTypesByUser(paymentTypes)}
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginTop: 9 }}
              onClick={handleSubmit(onSubmit)}
              fullWidth
            >
              Agregar
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="none">Id cliente</TableCell>
                  <TableCell padding="none">Modalidad de pago</TableCell>
                  <TableCell padding="none">Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerIds.map((ci) => (
                  <TableRow key={`${ci.paymentTypeCode}-${ci.paymentTypeName}`}>
                    <TableCell padding="none">{ci.idCliente}</TableCell>
                    <TableCell padding="none">{ci.paymentTypeName}</TableCell>
                    <TableCell padding="none">
                      <IconButton onClick={() => handleDeleteCustomerId(ci)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomerIds;
