import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import logo from 'assets/img/logo.png';
import useStyles from './change-password.styles';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { URL_CHANGE_PASSWORD } from 'constants/url';
import ListOfErrors from 'components/molecules/Form/ListOfErrors';
import { useEffect } from 'react';

export default function ChangePassword() {
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setConfirmErrorPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { showNotistack } = useCustomSnackbar();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const codeChangePassword = params.get('code') || '';
    if (!codeChangePassword) {
      setErrors(['Código no encontrado']);
      return;
    }

    setCode(codeChangePassword);
  }, []);
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password) {
      setErrorPassword('Debes ingresar la nueva contraseña');
      return;
    } else {
      setErrorPassword('');
    }
    if (!confirmPassword) {
      setConfirmErrorPassword('Debes confirmar la nueva contraseña');
      return;
    } else {
      setConfirmErrorPassword('');
    }
    if (password !== confirmPassword) {
      setErrorPassword('Las constraseñas no son iguales');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(URL_CHANGE_PASSWORD, { password, confirmPassword, code });
      if (response.status === 201) {
        showNotistack({ message: 'La contraseña fue cambiada exitosamente' });
        history.push('/login');
      }
    } catch (error) {
      if (error.request.status === 400) {
        const msg = Array.isArray(error.response.data?.message)
          ? error.response.data?.message[0]
          : 'Error no identificado';
        setErrorPassword(msg);
      } else {
        showNotistack({ message: 'Ocurrió un error, contacta a soporte' });
      }
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container justifyContent="center">
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <img src={logo} className="App-logo" alt="logo" width="400" />
          </Grid>
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <Typography component="h1" variant="h5" style={{ marginTop: 40 }}>
              Ingresa tu nueva contraseña
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
            {errors.length > 0 && (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <ListOfErrors errors={errors} />
              </Grid>
            )}
            <form noValidate onSubmit={onSubmit}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Nueva contraseña"
                  type="password"
                  id="password"
                  disabled={isLoading}
                  error={errorPassword ? true : false}
                  helperText={errorPassword}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar contraseña"
                  type="password"
                  id="confirmPassword"
                  disabled={isLoading}
                  error={errorConfirmPassword ? true : false}
                  helperText={errorConfirmPassword}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : ''} Guardar
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
