import React, { ReactElement, useState, useEffect, ChangeEvent } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import useWarehouse from 'hooks/useWarehouse';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useCities from 'hooks/useCities';
import { IWarehouse } from 'interfaces/warehouse.interface';
import { ICity } from 'interfaces/city.interface';

interface Props {
  handleCloseModal(): void;
  handleOnSubmit(data: IWarehouse): Promise<boolean>;
  getAllWarehouses(): void;
  warehouseId: string;
}

const defaultCity: ICity = {
  codigo: '',
  nombre: 'Selecciona uno',
  nombre_departamento: '',
};

export default function WarehouseForm({
  handleCloseModal,
  handleOnSubmit,
  warehouseId,
  getAllWarehouses,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { showNotistack } = useCustomSnackbar();
  const { handleSubmit, register, errors, reset } = useForm<IWarehouse>();
  const { getWarehouseById } = useWarehouse();
  const [city, setCity] = useState<ICity>(defaultCity);
  const [formDirty, setFormDirty] = useState(false);
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [departament, setDepartament] = useState<string>('Selecciona uno');
  const [departaments, setDepartaments] = useState<string[]>([]);
  const { getCities, getDepartaments, getCitiesByDepartament } = useCities();

  const onSubmit = (data: IWarehouse) => {
    setFormDirty(true);

    if (!city.codigo) {
      return;
    } else {
      data.cityCode = city.codigo;
      data.cityName = city.nombre;
    }

    setIsLoading(true);
    if (warehouseId) {
      data.id = warehouseId;
    }

    handleOnSubmit(data)
      .then((response: boolean) => {
        if (response === true) {
          showNotistack({ message: 'La bodega se guardó correctamente' });
          getAllWarehouses();
          handleCloseModal();
        } else {
          showNotistack({ message: 'Ocurrió un error' });
        }
      })
      .catch((err) => {
        console.log(err.reponse?.data);
        showNotistack({ message: 'Ocurrió un error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCities().then((allCitiesResult) => {
      setAllCities(allCitiesResult);
      setDepartaments(getDepartaments(allCitiesResult));
      if (warehouseId) {
        setIsLoading(true);
        getWarehouseById(warehouseId)
          .then((resultWarehouse: IWarehouse) => {
            setCity({
              codigo: resultWarehouse.cityCode,
              nombre: resultWarehouse.cityName || '',
              nombre_departamento: '',
            });
            const department = allCitiesResult.find((c) => c.codigo === resultWarehouse.cityCode);
            if (department) {
              setDepartament(department.nombre_departamento);
            }
            setCities(getCitiesByDepartament(allCitiesResult, resultWarehouse.cityDepartamentName));
            reset(resultWarehouse);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  }, [warehouseId]);

  useEffect(() => {
    setCities(getCitiesByDepartament(allCities, departament));
  }, [allCities]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" spacing={2} justifyContent="center">
        {/* name */}
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            margin="normal"
            fullWidth
            name="name"
            label="Nombre"
            autoComplete="name"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
            })}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {/* departamento */}
        <Grid item xs={12}>
          <Autocomplete
            value={departament}
            fullWidth
            // getOptionLabel={(option) => option.name}
            onChange={(_, value: string | null) => {
              setDepartament(value ? value : '');
              if (value) {
                setCities(getCitiesByDepartament(allCities, value));
              }
              setCity(defaultCity);
            }}
            id="departamento"
            options={departaments}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Departamento"
                disabled={isLoading}
                error={formDirty && !departament}
                helperText={formDirty && !departament ? 'Este campo es requerido' : ''}
                variant="outlined"
              />
            )}
          />
        </Grid>
        {/* city */}
        <Grid item xs={12}>
          <Autocomplete
            value={city}
            fullWidth
            getOptionLabel={(option) => option.nombre}
            onChange={(event: any, newCity: ICity | null) => {
              if (newCity) {
                setCity(newCity);
              }
            }}
            id="city"
            options={cities}
            style={{ marginTop: 15 }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Ciudad"
                disabled={isLoading}
                error={formDirty && !city.codigo}
                helperText={formDirty && !city.codigo ? 'Este campo es requerido' : ''}
                variant="outlined"
              />
            )}
          />
        </Grid>
        {/* address */}
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            margin="normal"
            fullWidth
            label="Dirección"
            name="address"
            autoComplete="address"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
            })}
            error={errors.address ? true : false}
            helperText={errors.address && errors.address.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ marginTop: 20 }}
          onClick={() => {
            setFormDirty(true);
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Guardar
        </Button>
        <Button
          variant="contained"
          color="default"
          disabled={isLoading}
          style={{ marginTop: 20, marginLeft: 20 }}
          onClick={handleCloseModal}
        >
          Cancelar
        </Button>
      </Grid>
    </form>
  );
}
