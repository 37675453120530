import { useCallback, useContext, useState } from 'react';
import Context, { User } from 'contextApi/UserContext';
import loginService from 'services/loginService';
import { ACCESS_TOKEN_NAME, USER_KEY_STORE } from 'constants/securityConts';
import { URL_USER_PAYMENT_TYPES } from 'constants/url';
import axios, { AxiosResponse } from 'axios';
import { PaymentTypesByUser } from 'interfaces/paymentTypes.interface';
import { CustomerId } from 'interfaces/customersId.interface';

type State = {
  loading: boolean;
  error: boolean;
};

export default function useUser() {
  const [state, setState] = useState<State>({ loading: false, error: false });
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useUser debe ser usado dentro de UserContextProvider');
  }

  const login = useCallback(
    ({ email, password }) => {
      setState({ loading: true, error: false });
      loginService({ email, password })
        .then((response) => {
          let err = false;

          if (response?.accessToken) {
            window.localStorage.setItem(ACCESS_TOKEN_NAME, response.accessToken);
            const userToSave = {
              id: response.id,
              firstName: response.firstName,
              email: response.email,
              role: response.role,
              isConfigurationComplete: response.isConfigurationComplete,
            };

            window.localStorage.setItem(USER_KEY_STORE, JSON.stringify(userToSave));
            context.setJwt(response.accessToken);
            context.setUser(userToSave);
          } else {
            err = true;
          }
          setState({ loading: false, error: err });
        })
        .catch((err) => {
          window.localStorage.removeItem(ACCESS_TOKEN_NAME);
          window.localStorage.removeItem(USER_KEY_STORE);
          setState({ loading: false, error: true });
          console.error(err);
        });
    },
    [context.setJwt]
  );

  const logout = useCallback(() => {
    window.localStorage.removeItem(ACCESS_TOKEN_NAME);
    window.localStorage.removeItem(USER_KEY_STORE);
    window.localStorage.removeItem('shopName');
    context.setJwt(null);
  }, [context.setJwt]);

  const setIsConfigurationComplete = (value: boolean) => {
    const newUser: User = {
      id: context.user?.id || '',
      firstName: context.user?.firstName || '',
      email: context.user?.email || '',
      role: context.user?.role || '',
      isConfigurationComplete: value,
    };

    context.setUser(newUser);
    const userStored = JSON.parse(window.localStorage.getItem(USER_KEY_STORE) || '');
    userStored.isConfigurationComplete = true;
    window.localStorage.setItem(USER_KEY_STORE, JSON.stringify(userStored));
  };

  const getPaymentTypesByUser = async (): Promise<CustomerId[]> => {
    return axios
      .get<CustomerId[]>(URL_USER_PAYMENT_TYPES, {
        headers: {
          Authorization: `Bearer ${context.jwt}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  };

  return {
    isLogged: Boolean(context.jwt),
    jwt: context.jwt,
    setJwt: context.setJwt,
    isLoading: state.loading,
    login,
    logout,
    hasLoginError: state.error,
    user: context.user,
    setIsConfigurationComplete,
    getPaymentTypesByUser,
  };
}
