import React, { ReactElement } from 'react';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Typography } from '@material-ui/core';

export default function NotFoundPage(): ReactElement {
  return (
    <AdminTemplate>
      <Typography variant="h5" component="h1" align="center">
        404 Page not found
      </Typography>
    </AdminTemplate>
  );
}
