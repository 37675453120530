import { URL_PRODUCTS, URL_PRODUCTS_SAVE_WAREHOUSE } from 'constants/url';
import axios from 'axios';
import { Pagination } from 'interfaces/pagination.interface';
import { WCIProduct } from 'interfaces/product.interface';

interface GetAllProducts {
  jwt: string | null;
  pagination: Pagination & { name: string };
}
export interface ProductsResponse {
  data: WCIProduct[];
  total: number;
  totalPages: number;
}
export function getAllProducts({ jwt, pagination }: GetAllProducts): Promise<ProductsResponse> {
  const { limit, page, name } = pagination;
  return new Promise((resolve, reject) => {
    axios
      .get<ProductsResponse>(`${URL_PRODUCTS}?name=${name}&page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject('Respuesta no esperada');
        }
      })
      .catch((err) => {
        console.log(err);
        reject('Ocurrió un error, por favor contacta con soporte.');
      });
  });
}

interface SaveWarehouseProduct {
  jwt: string | null;
  products: number[];
  cityCode: string;
  warehouseId: string;
}

export function updateWarehouseProduct({
  jwt,
  products,
  cityCode,
  warehouseId,
}: SaveWarehouseProduct): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(
        URL_PRODUCTS_SAVE_WAREHOUSE,
        {
          products,
          cityCode,
          warehouseId,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject('Respuesta no esperada');
        }
      })
      .catch((err) => {
        console.log(err);
        reject('Ocurrión un error, por favor contacta con soporte.');
      });
  });
}
