import React, { ReactElement } from 'react';
import MuiTable from 'components/molecules/MuiTable';
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import { Button } from '@material-ui/core';
import CustomToolbar from 'components/molecules/CustomToolBarMuiDatatable/CustomToolbarMuiDatatable';
import textLabels from 'utils/MUIDataTableTextLabels';
import { IWarehouse } from 'interfaces/warehouse.interface';
interface Props {
  warehouses: IWarehouse[];
  isLoading: boolean;
  addButton(): void;
  handleOnClickEditPackage(packageId: string): void;
  handleOnClickDeletePackage(packageId: string): void;
}

export default function PackagesList({
  warehouses,
  isLoading,
  addButton,
  handleOnClickEditPackage,
  handleOnClickDeletePackage,
}: Props): ReactElement {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: 'false',
      },
    },
    {
      name: 'name',
      label: 'Bodega',
    },
    {
      name: 'address',
      label: 'Dirección',
    },
    {
      name: 'cityName',
      label: 'Ciudad',
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <React.Fragment>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  handleOnClickEditPackage(tableMeta.rowData[0]);
                }}
              >
                Editar
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="default"
                onClick={() => {
                  handleOnClickDeletePackage(tableMeta.rowData[0]);
                }}
                style={{ marginLeft: 20 }}
              >
                Eliminar
              </Button>
            </React.Fragment>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    textLabels,
    download: false,
    filter: false,
    print: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    viewColumns: false,
    customToolbar: () => {
      return <CustomToolbar tooltip="Agregar bodega" onClick={addButton} />;
    },
  };

  return (
    <div>
      <MuiTable
        title="Lista de bodegas"
        isLoading={isLoading}
        columns={columns}
        data={warehouses}
        options={options}
      />
    </div>
  );
}
