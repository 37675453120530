import React, { ReactElement, useEffect, useState } from 'react';
import ModalContainer from 'components/molecules/ModalContainer';
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import usePackage from 'hooks/usePackage';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { IPackage } from 'interfaces/shippingPackage.interface';

interface Props {
  open: boolean;
  packageId: string;
  onClose(): void;
  getAllPackages(): void;
}

export default function PackageDeleteModal({
  open,
  onClose,
  packageId,
  getAllPackages,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState<IPackage>();
  const { getPackageById, deletePackage } = usePackage();
  const { showNotistack } = useCustomSnackbar();

  useEffect(() => {
    if (packageId) {
      setIsLoading(true);
      getPackageById(packageId)
        .then((resultPackage: IPackage) => {
          setPackageToDelete(resultPackage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [packageId]);

  const handleOnClick = () => {
    deletePackage(packageId).then((response) => {
      if (response === true) {
        showNotistack({ message: 'El paquete se eliminó correctamente' });
        getAllPackages();
        onClose();
      } else {
        showNotistack({ message: 'Ocurrió un error al eliminar el paquete' });
      }
    });
  };
  return (
    <ModalContainer open={open} title="Eliminar paquete">
      <Typography variant="h5">
        {`Estas seguro que deseas eliminar el registro: '${packageToDelete?.name}'?`}
      </Typography>
      <Grid container justifyContent="center">
        <Button
          onClick={handleOnClick}
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ marginTop: 20 }}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Si
        </Button>
        <Button
          variant="contained"
          color="default"
          disabled={isLoading}
          style={{ marginTop: 20, marginLeft: 20 }}
          onClick={onClose}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Cancelar
        </Button>
      </Grid>
    </ModalContainer>
  );
}
