const convertToCurrencyCop = (value: string): string => {
  const newValue = parseInt(value.replace(/\D/g, ''), 10);

  const result = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
  })
    .format(isNaN(newValue) ? 0 : newValue)
    .replace(/\D00$/, '');

  if (result === '$ 0') {
    return '';
  }
  return result;
};

export default convertToCurrencyCop;
