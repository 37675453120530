export const URL_BASE =
  process.env.NODE_ENV === 'production'
    ? 'https://wc-backend-dot-cm-integraciones.uk.r.appspot.com'
    : 'http://localhost:4605';
export const URL_FRONT =
  process.env.NODE_ENV === 'production' ? `https://wc.coordinadora.com` : 'http://127.0.0.1:3000';

export const URL_WOOCOMMERCE_RETURN = `${URL_FRONT}/admin/connect-store`;
export const URL_WOOCOMMERCE_CALLBACK = `${URL_BASE}/api/wc-configurations/callback`;
export const URL_WCCONFIGURATIONS = `${URL_BASE}/api/wc-configurations`;
export const URL_CREATE_ACCOUNT = `${URL_BASE}/api/Accounts`;

export const URL_REGISTER_ACCOUNT = `${URL_BASE}/Register`;
export const URL_LOGIN = `${URL_BASE}/api/Auth/login`;
export const URL_RECOVER_PASSWORD = `${URL_BASE}/api/recover-password/send-email`;
export const URL_CHANGE_PASSWORD = `${URL_BASE}/api/recover-password/change-password`;
export const URL_ORDERS = `${URL_BASE}/api/Orders`;
export const URL_PRODUCTS = `${URL_BASE}/api/Products`;
export const URL_PRODUCTS_SAVE_WAREHOUSE = `${URL_BASE}/api/Products/saveWarehouse`;
export const URL_CONFIGURE_SHIPPING_CARRIER = `${URL_BASE}/api/ConfigurationShippingCarrier`;
export const URL_SHIPPING_CARRIER_VALIDATE_CONFIGURATION = `${URL_CONFIGURE_SHIPPING_CARRIER}/isConfigurationComplete`;
export const URL_SHIPPING = `${URL_BASE}/api/coordinadoraWs`;
export const URL_GENERATE_PDF_TRACKING_NUMBERS = `${URL_BASE}/api/coordinadoraWs/GeneratePDFOfTrackingNumbers`;

export const URL_WAREHOUSE = `${URL_BASE}/api/Warehouses`;

export const URL_SCHEDULE_PICKUP = `${URL_BASE}/api/SchedulePickup`;

export const URL_CITIES = `${URL_BASE}/api/Cities`;
export const URL_PACKAGES = `${URL_BASE}/api/Packages`;
export const URL_PAYMENT_TYPES = `${URL_BASE}/api/PaymentTypes`;
export const URL_USER_PAYMENT_TYPES = `${URL_BASE}/api/Users/PaymentTypes`;
