import React, { ReactElement, useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import useUser from 'hooks/useUser';
import Appbar from 'components/organisms/AppBar';
import Footer from 'components/organisms/Footer';
import axiosInterceptor from 'utils/axiosInterceptor';
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Grid,
  Link,
  Hidden,
} from '@material-ui/core';
import useStyles from './adminTemplate.styles';
import routes from 'routes';

axiosInterceptor();

interface Props {
  children: React.ReactNode;
}

interface IRoutes {
  [key: string]: string[];
}
export default function Admin({ children }: Props): ReactElement {
  const { logout, isLogged, user } = useUser();
  const routesByRole = user && user.role ? routes[user.role.toLowerCase()] : [];
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    if (user?.isConfigurationComplete !== true) {
      history.push('/admin/settings');
    }
    if (!isLogged) {
      logout();
      history.push('/login');
    }
  }, [isLogged]);

  const activeRoute = (routeName: string) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Appbar logout={logout} routes={[]} accountText={user && user.email ? user.email : ''} />
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div>
              <List>
                {routesByRole.map((route) => (
                  <Link
                    component={RouterLink}
                    to={route.path}
                    underline="none"
                    color="inherit"
                    key={route.path}
                  >
                    <ListItem
                      button
                      key={route.path}
                      className={classes.listItems}
                      selected={activeRoute(route.path)}
                    >
                      <ListItemIcon>
                        <route.icon />
                      </ListItemIcon>
                      <ListItemText primary={route.name} />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </div>
          </Drawer>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div>
            <List>
              {routesByRole.map((route) => (
                <Link
                  component={RouterLink}
                  to={route.path}
                  underline="none"
                  color="inherit"
                  key={route.path}
                >
                  <ListItem
                    button
                    key={route.path}
                    className={classes.listItems}
                    selected={activeRoute(route.path)}
                  >
                    <ListItemIcon>
                      <route.icon />
                    </ListItemIcon>
                    <ListItemText primary={route.name} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </Drawer>
      </Hidden>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.content}>{children}</div>
        </Grid>
        <Footer />
      </Grid>
    </div>
  );
}
