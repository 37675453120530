import React, { useReducer, useState } from 'react';
import ordersReducer, { initialStateOrdersReducer } from 'reducers/Orders.reducer';
import {
  UPDATE_ORDERS,
  UPDATE_ISlOADING_ORDERS,
  SET_ORDER_TO_SHIPPING,
  OPEN_MODAL_CREATE_SHIPPING,
} from 'actions/orders.actions';
import { getAllOrders } from 'services/ordersService';
import useUser from 'hooks/useUser';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { IOrder, IOrderToShipping, IShipping, Pagination } from 'interfaces';

const defaultPagination: Pagination = {
  limit: 20,
  page: 1,
};

type State = {
  isLoadingOrders: boolean;
  totalOrders: number;
  orders: IOrder[];
  getOrders(page: number, limit: number, search: string): void;
  orderToShipping: IOrderToShipping;
  setOrderToShipping(orderToShipping: IOrderToShipping): void;
  openModalCreateShipping: boolean;
  handleOpenModalCreateShipping(value: boolean): void;
  shipping: IShipping;
  updatePagination(state: Pagination): void;
  pagination: Pagination;
};

interface Props {
  children: React.ReactNode;
}

const OrdersContext = React.createContext<State | undefined>(undefined);

export function OrdersContextProvider({ children }: Props) {
  const history = useHistory();

  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  const updatePagination = (state: Pagination) => {
    setPagination((oldState) => ({ ...oldState, ...state }));
  };

  const [
    { orders, totalOrders, isLoadingOrders, openModalCreateShipping, shipping, orderToShipping },
    dispatch,
  ] = useReducer(ordersReducer, initialStateOrdersReducer);
  const { jwt } = useUser();

  const getOrders = (page: number, limit: number) => {
    dispatch({ type: UPDATE_ISlOADING_ORDERS, payload: true });
    getAllOrders({ jwt, pagination: { limit, page } })
      .then((orderResponse) => {
        if (orderResponse) {
          dispatch({
            type: UPDATE_ORDERS,
            payload: {
              orders: orderResponse.data,
              total: orderResponse.total,
              totalPages: orderResponse.totalPages,
              isLoading: false,
            },
          });

          updatePagination({
            ...pagination,
          });
        }
      })
      .catch((err: AxiosError) => {
        console.log(err.response?.status);

        if (err.response?.status === 403) {
          history.push(`/admin/connect-store`);
        }
      })
      .finally(() => {
        dispatch({
          type: UPDATE_ISlOADING_ORDERS,
          payload: false,
        });
      });
  };

  const setOrderToShipping = (orderToShippingToSet: IOrderToShipping) => {
    dispatch({ type: SET_ORDER_TO_SHIPPING, payload: orderToShippingToSet });
  };

  const handleOpenModalCreateShipping = (value: boolean) => {
    dispatch({ type: OPEN_MODAL_CREATE_SHIPPING, payload: value });
  };

  return (
    <OrdersContext.Provider
      value={{
        isLoadingOrders: isLoadingOrders,
        orders: orders,
        totalOrders,
        getOrders,
        setOrderToShipping,
        openModalCreateShipping,
        handleOpenModalCreateShipping,
        updatePagination,
        pagination,
        shipping,
        orderToShipping,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export const useOrdersContext = () => {
  const context = React.useContext(OrdersContext);
  if (!context) {
    throw new Error(
      'useOrderContext debe estar dentro del proveedor dentro de OrdersContextProvider'
    );
  }
  return context;
};
