import axios, { AxiosResponse } from 'axios';
import useUser from './useUser';

import { URL_WAREHOUSE } from 'constants/url';
import { IWarehouse } from 'interfaces/warehouse.interface';

export default function useWarehouse() {
  const { jwt } = useUser();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getWarehouses = (): Promise<IWarehouse[]> => {
    return axios.get(URL_WAREHOUSE, { headers }).then((result: AxiosResponse) => {
      return result.data;
    });
  };

  const getWarehouseById = (wareHouseId: string): Promise<IWarehouse> => {
    return axios
      .get(`${URL_WAREHOUSE}/${wareHouseId}`, { headers })
      .then((result: AxiosResponse) => {
        return result.data;
      });
  };

  const addWarehouse = (data: any): Promise<boolean> => {
    return axios.post(URL_WAREHOUSE, data, { headers }).then((response: AxiosResponse) => {
      return response.status === 201;
    });
  };

  const updateWarehouse = (data: any): Promise<boolean> => {
    if (!data.id) throw new Error('El campo id es obligatorio');
    return axios
      .put(`${URL_WAREHOUSE}/${data.id}`, data, { headers })
      .then((response: AxiosResponse) => {
        return response.status === 204;
      });
  };

  const deleteWarehouse = (warehouseId: string): Promise<boolean> => {
    if (!warehouseId) throw new Error('El campo id es obligatorio');
    return axios
      .delete(`${URL_WAREHOUSE}/${warehouseId}`, { headers })
      .then((response: AxiosResponse) => {
        return response.status === 204;
      });
  };

  return {
    getWarehouses,
    getWarehouseById,
    addWarehouse,
    updateWarehouse,
    deleteWarehouse,
  };
}
