import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

export default function AlertDialogSlide() {
  const [openModal, setOpenModal] = React.useState(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      open={openModal}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-configure-shipping-carrier"
      aria-describedby="alert-dialog-configure-shipping-carrier-description"
    >
      <DialogTitle id="alert-dialog-configure-shipping-carrier">
        {'Instrucciones de configuración'}
      </DialogTitle>
      <DialogContent>
        <Typography component="h1">
          Sigue las intrucciones para finalizar la configurración.
        </Typography>
        <Typography component="h5"></Typography>
        <p>
          <strong>1.</strong> Obten los datos para la integración con Coordinadora, solicitalos a tu
          asesor o si no tienes asesor asignado envía un mensaje informando la ciudad donde te
          encuetras al email soporteplugins@coordinadora.com
        </p>
        <p>
          <strong>2.</strong> Ingresa los datos de la integración en la sección Configuración.
        </p>
        <p>
          <strong>3.</strong> Crea mínimo una bodega.
        </p>
        <p>
          <strong>4.</strong> Crea mínimo un tipo de paquete.
        </p>
        <p>
          <strong>5.</strong> Crea tus guías.
        </p>
        <p>
          <strong>6.</strong> Elige una ciudad.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
