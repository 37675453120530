import Orders from './Orders';
import { OrdersContextProvider } from 'contextApi/OrdersContext';
import React from 'react';

export default function (props: any) {
  return (
    <OrdersContextProvider>
      <Orders {...props} />
    </OrdersContextProvider>
  );
}
