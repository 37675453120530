export const convertErrorsApiToErrorsForm = (errorSourse: any, newErrors: any): any => {
  const userErrors: any = { ...newErrors };
  if (Array.isArray(errorSourse)) {
    let isString = true;
    errorSourse.forEach((element: any) => {
      if (typeof element !== 'string') {
        isString = false;
      }
    });
    if (isString) {
      userErrors.othersErrors = errorSourse;
      return userErrors;
    }
  }

  if (typeof errorSourse.errors === 'object') {
    for (const key in errorSourse.errors) {
      if (Object.prototype.hasOwnProperty.call(errorSourse.errors, key)) {
        const value: Array<string> = errorSourse.errors[key];
        for (const keyUserErrors in userErrors) {
          if (Object.prototype.hasOwnProperty.call(userErrors, keyUserErrors)) {
            if (key.toLowerCase() === keyUserErrors.toLowerCase()) {
              userErrors[keyUserErrors] = value[0];
            }
          }
        }
      }
    }
    return userErrors;
  }

  if (typeof errorSourse.error === 'string') {
    userErrors.othersErrors = [errorSourse.message];
    return userErrors;
  }
  console.log('convertErrorsApiToErrorsForm: No se pudo identificar la estructura de respuesta');
  return userErrors;
};
