import React, { ReactElement } from 'react';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables';
import textLabels from 'utils/MUIDataTableTextLabels';
import { Typography, CircularProgress } from '@material-ui/core';

interface Props {
  columns: MUIDataTableColumnDef[];
  options?: MUIDataTableOptions;
  isLoading: boolean;
  data: any[];
  title: string;
}

const optionsDefault: MUIDataTableOptions = {
  print: false,
  filter: false,
  viewColumns: false,
  download: false,
  selectableRows: 'none',
  rowHover: true,
  textLabels,
  rowsPerPageOptions: [],
};
export default function MuiTable({
  columns,
  options = optionsDefault,
  isLoading,
  data,
  title,
}: Props): ReactElement {
  return (
    <MUIDataTable
      title={
        <Typography variant="h6">
          {title}
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />
          )}
        </Typography>
      }
      data={data}
      columns={columns}
      options={options}
    />
  );
}
