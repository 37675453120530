import React, { ReactElement, useEffect, useState } from 'react';
import RegisterForm from 'components/organisms/RegisterForm';
import { RegisterUserProvider } from 'contextApi/RegisterContextApi';
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography } from '@material-ui/core';
import useStyles from './register.styles';
import imgAlcance from 'assets/img/benefits/01.jpg';
import imgFacilIntegracion from 'assets/img/benefits/02.jpg';
import imgGestiona from 'assets/img/benefits/03.jpg';
import logo from 'assets/img/logo.png';
export default function Register(): ReactElement {
  const [isMounted, setIsMounted] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (isMounted) {
    return (
      <RegisterUserProvider>
        <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <img src={logo} className="App-logo" alt="logo" width="400" />
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} sm={6}>
            <Grid container justifyContent="center" spacing={2} style={{ padding: 40 }}>
              <Grid item md={12} style={{ marginBottom: 20 }}>
                <Typography variant="h4" component="h1" align="center">
                  Comienza a crear tus guías en segundos
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={imgAlcance}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Automatización de guías
                      </Typography>
                      <Typography variant="body2" color="textPrimary" component="p">
                        Si tienes tu tienda en Colombia puedes Generar guías en Coordinadora
                        Mercantil vinculanto tu tienda de Woocommerce.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={imgFacilIntegracion}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Solicita tus recogidas
                      </Typography>
                      <Typography variant="body2" color="textPrimary" component="p">
                        De una manera intuitiva y sencilla podrás solicitar la recogida de tus
                        pedidos.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.media}
                      image={imgGestiona}
                      title="Contemplative Reptile"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Precio
                      </Typography>
                      <Typography variant="body2" color="textPrimary" component="p">
                        Te cobramos solo por el flete dependiendo del origen, destino, dimensiones y
                        peso del paquete según el acuerdo que .
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sm={6}>
            <RegisterForm />
          </Grid>
        </Grid>
      </RegisterUserProvider>
    );
  }
  return <React.Fragment></React.Fragment>;
}
