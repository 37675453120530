import React, { ReactElement, useState, useEffect } from 'react';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Grid, Typography } from '@material-ui/core';
import PackagesList from 'components/organisms/PackagesList/PackagesList';
import ModalContainer from 'components/molecules/ModalContainer';
import PackageForm from 'components/organisms/PackageForm';
import usePackage from 'hooks/usePackage';
import PackageDeleteModal from 'components/organisms/PackageDeleteModal';
import { IPackage } from 'interfaces/shippingPackage.interface';

export default function Packages(): ReactElement {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [packageId, setPackageId] = useState('');
  const { getPackages, addPackage, updatePackage } = usePackage();

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  const getAllPackages = () => {
    getPackages().then((result: IPackage[]) => {
      if (result.length > 0) {
        setPackages(result);
      }
    });
  };

  const savePackage = (data: IPackage) => {
    console.log(data);

    if (data.id) {
      return updatePackage(data);
    } else {
      return addPackage(data);
    }
  };
  const addButton = () => {
    setPackageId('');
    setOpenModal(true);
  };

  const handleOnClickEditPackage = (newPackageId: string) => {
    setPackageId(newPackageId);
    setOpenModal(true);
  };

  const handleOnClickDeletePackage = (newPackageId: string) => {
    setPackageId(newPackageId);
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" component="h1" align="center">
            Gestión de tipos de paquetes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <PackagesList
            isLoading={false}
            packages={packages}
            addButton={addButton}
            handleOnClickEditPackage={handleOnClickEditPackage}
            handleOnClickDeletePackage={handleOnClickDeletePackage}
          />
        </Grid>
      </Grid>
      <ModalContainer title="Crear paquete" open={openModal} onClose={handleOnCloseModal}>
        <PackageForm
          handleCloseModal={handleOnCloseModal}
          handleOnSubmit={savePackage}
          packageId={packageId}
          getAllPackages={getAllPackages}
        />
      </ModalContainer>
      <PackageDeleteModal
        onClose={() => {
          setOpenDeleteModal(!openDeleteModal);
        }}
        open={openDeleteModal}
        packageId={packageId}
        getAllPackages={getAllPackages}
      ></PackageDeleteModal>
    </AdminTemplate>
  );
}
