import React, { useEffect } from 'react';
import { Button, FormControl, Grid, Paper, TextField, useMediaQuery } from '@material-ui/core';
import { subDays } from 'date-fns';
import useCustomSnackbar from 'hooks/useCustomSnackbar';

// Datepicker
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateFnsEsLocale from 'date-fns/locale/es';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { OrdersFiltersData } from 'interfaces/order.interface';

interface Props {
  handleGetOrders(filters: OrdersFiltersData): void;
}

const nowDate = new Date();

export default function OrdersFilters({ handleGetOrders }: Props) {
  const [startDate, setStartDate] = React.useState<Date>(subDays(nowDate, 15));
  const [endDate, setEndDate] = React.useState<Date>(nowDate);
  const [orderId, setOrderId] = React.useState<string>('');
  const [trackingNumber, setTrackingNumber] = React.useState<string>('');
  const { showNotistack } = useCustomSnackbar();

  const isExtraLarge = useMediaQuery('(min-width:1440px)');

  const reset = () => {
    setOrderId('');
    setTrackingNumber('');
  };

  useEffect(() => {
    handleGetOrders({
      startDate,
      endDate,
      orderId,
      trackingNumber,
    });
  }, []);

  return (
    <Grid item xs={12}>
      <Paper
        style={{
          padding: isExtraLarge ? '.75rem' : '.75rem .5rem',
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (subDays(endDate, 60).getTime() > startDate.getTime()) {
              showNotistack({ message: 'Rango máximo entre fechas es de 60 días' });
              return;
            }

            handleGetOrders({
              startDate,
              endDate,
              orderId,
              trackingNumber,
            });
          }}
          noValidate
        >
          <Grid
            container
            spacing={1}
            justifyContent={isExtraLarge ? 'center' : 'flex-start'}
            alignItems="center"
          >
            <Grid item xs={12} sm={isExtraLarge ? 'auto' : 3} style={{ height: '72px' }}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsEsLocale}>
                  <DateTimePicker
                    fullWidth
                    value={startDate}
                    label="Fecha desde"
                    format="yyyy-MM-dd HH:mm"
                    onChange={(date: MaterialUiPickersDate) => {
                      if (date != null) setStartDate(date);
                    }}
                    inputVariant="outlined"
                    size="small"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={isExtraLarge ? 'auto' : 3} style={{ height: '72px' }}>
              <FormControl fullWidth variant="outlined">
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsEsLocale}>
                  <DateTimePicker
                    fullWidth
                    value={endDate}
                    label="Fecha hasta"
                    format="yyyy-MM-dd HH:mm"
                    onChange={(date: MaterialUiPickersDate) => {
                      if (date != null) setEndDate(date);
                    }}
                    inputVariant="outlined"
                    size="small"
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={isExtraLarge ? 'auto' : 3} style={{ height: '72px' }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Pedido"
                size="small"
                value={orderId}
                onChange={({ target: { value } }) => setOrderId(value)}
                onFocus={() => setTrackingNumber('')}
              />
            </Grid>
            <Grid item xs={12} sm={isExtraLarge ? 'auto' : 3} style={{ height: '72px' }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Número de guía"
                size="small"
                value={trackingNumber}
                onChange={({ target: { value } }) => setTrackingNumber(value)}
                onFocus={() => setOrderId('')}
              />
            </Grid>
            <Grid item xs={12} sm="auto" style={{ height: '72px' }}>
              <Button variant="contained" color="primary" type="submit">
                Buscar
              </Button>
              <Button variant="contained" onClick={reset} style={{ marginLeft: '10px' }}>
                Limpiar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
