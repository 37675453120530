import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 16,
    width: '100%',
  },
  backgroundGrid: {
    background: 'white',
    borderRadius: 5,
  },
  marginFields: {
    marginTop: 20,
  },
}));
