import { getAllProducts, ProductsResponse, updateWarehouseProduct } from 'services/productsService';
import useUser from 'hooks/useUser';
import { Pagination } from 'interfaces/pagination.interface';

export default function useProducts() {
  const { jwt } = useUser();

  const getProducts = async (
    pagination: Pagination & { name: string }
  ): Promise<ProductsResponse> => {
    return await getAllProducts({ jwt, pagination });
  };

  const saveWarehouse = async (products: any[], cityCode: string, warehouseId: string) => {
    return await updateWarehouseProduct({ jwt, products, cityCode, warehouseId });
  };

  return {
    getProducts,
    saveWarehouse,
  };
}
