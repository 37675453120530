import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from 'components/pages/Home/Home';
import ConnectStore from 'components/pages/ConnectStore';
import Register from 'components/pages/Register';
import Login from 'components/pages/Login';
import { SnackbarProvider } from 'notistack';
import Dashboard from 'components/pages/Dashboard';
import Orders from 'components/pages/Orders';
import Products from 'components/pages/Products';
import SchedulePickup from 'components/pages/SchedulePickup';
import NotFoundPage from 'components/pages/NotFound';
import Settings from 'components/pages/ConfigureShippingCarrier';
import Warehouse from 'components/pages/Warehouse';
import Packages from 'components/pages/Packages';
import RecoverPassword from 'components/pages/RecoverPassword';
import ChangePassword from 'components/pages/ChangePassword';

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/recover-password" component={RecoverPassword} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/admin/dashboard" component={Dashboard} exact />
          <Route path="/admin/orders" component={Orders} exact />
          <Route path="/admin/products" component={Products} exact />
          <Route path="/admin/schedule" component={SchedulePickup} exact />
          <Route path="/admin/warehouse" component={Warehouse} exact />
          <Route path="/admin/packages" component={Packages} exact />
          <Route path="/admin/connect-store" component={ConnectStore} exact />
          <Route path="/admin/settings" component={Settings} />
          <Route path="/admin" component={Dashboard} exact />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
