import React, { ReactElement, useState, useEffect } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import usePackage from 'hooks/usePackage';
import { IPackage } from 'interfaces/shippingPackage.interface';
interface Props {
  handleCloseModal(): void;
  handleOnSubmit(data: IPackage): Promise<boolean>;
  getAllPackages(): void;
  packageId: string;
}

export default function PackageForm({
  handleCloseModal,
  handleOnSubmit,
  packageId,
  getAllPackages,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const { showNotistack } = useCustomSnackbar();
  const { handleSubmit, register, errors, reset } = useForm<IPackage>();
  const { getPackageById } = usePackage();

  const onSubmit = (data: IPackage) => {
    data.long = Number(data.long);
    data.width = Number(data.width);
    data.height = Number(data.height);
    data.weight = Number(data.weight);

    setIsLoading(true);
    if (packageId) {
      data.id = packageId;
    }
    handleOnSubmit(data)
      .then((response: boolean) => {
        if (response === true) {
          showNotistack({ message: 'El paquete se guardó correctamente' });
          getAllPackages();
          handleCloseModal();
        } else {
          showNotistack({ message: 'Ocurrió un error' });
        }
      })
      .catch(() => {
        showNotistack({ message: 'Ocurrió un error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const validatePackageInput = (value: any) => {
    console.log(value);

    if (Number.isInteger(Number(value)) === false) {
      return 'Este campo debe ser número entero';
    }
    if (value < 1) {
      return 'Este campo debe ser mayor a 0';
    }
  };

  const handleKeywordKeyPress = (e: React.KeyboardEvent) => {
    const regExp = /^[0-9\b]+$/;
    if (!regExp.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (packageId) {
      setIsLoading(true);
      getPackageById(packageId)
        .then((resultPackage: IPackage) => {
          reset(resultPackage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [packageId]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Nombre del paquete"
            name="name"
            autoComplete="name"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
            })}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Alto (cm)"
            name="height"
            fullWidth
            autoComplete="height"
            disabled={isLoading}
            type="number"
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              validate: validatePackageInput,
            })}
            onKeyPress={handleKeywordKeyPress}
            error={errors.height ? true : false}
            helperText={errors.height && errors.height.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Ancho (cm)"
            name="width"
            fullWidth
            type="number"
            autoComplete="width"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              validate: validatePackageInput,
            })}
            onKeyPress={handleKeywordKeyPress}
            error={errors.width ? true : false}
            helperText={errors.width && errors.width.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Largo (cm)"
            name="long"
            fullWidth
            type="number"
            autoComplete="long"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              validate: validatePackageInput,
            })}
            onKeyPress={handleKeywordKeyPress}
            error={errors.long ? true : false}
            helperText={errors.long && errors.long.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Peso (kilos)"
            name="weight"
            fullWidth
            autoComplete="weight"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              validate: validatePackageInput,
            })}
            type="number"
            onKeyPress={handleKeywordKeyPress}
            error={errors.weight ? true : false}
            helperText={errors.weight && errors.weight.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ marginTop: 20 }}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Guardar
        </Button>
        <Button
          variant="contained"
          color="default"
          disabled={isLoading}
          style={{ marginTop: 20, marginLeft: 20 }}
          onClick={handleCloseModal}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Cancelar
        </Button>
      </Grid>
    </form>
  );
}
