import React, { ReactElement } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';

interface Props {
  errors: string[];
}

const createList = (errors: string[]) =>
  errors.map((err: string, index: number) => (
    <FormControl error key={index}>
      <FormHelperText>{err}</FormHelperText>
    </FormControl>
  ));

export default function ListOfErrors({ errors }: Props): ReactElement {
  return <React.Fragment>{createList(errors)}</React.Fragment>;
}
