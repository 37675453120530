/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import convertToCurrencyCop from 'utils/convertToCurrencyCop';
import { useForm, Controller } from 'react-hook-form';
import ModalContainer from 'components/molecules/ModalContainer';
import {
  FormControl,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CircularProgress,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Paper,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import useWarehouse from 'hooks/useWarehouse';
import usePackage from 'hooks/usePackage';
import useCities from 'hooks/useCities';
import useUser from 'hooks/useUser';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useShipping from 'hooks/useShippingCarrier';
import { downloadPDF } from 'utils/downloadPDF';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { useOrdersContext } from 'contextApi/OrdersContext';
import paymentTypesService from 'services/paymentTypesService';
import { IWarehouse } from 'interfaces/warehouse.interface';
import { ICity } from 'interfaces/city.interface';
import { IPackage } from 'interfaces/shippingPackage.interface';
import { PaymentType } from 'interfaces/paymentTypes.interface';
import { IShippingRequest } from 'interfaces/shipping.interface';
import { IDefaultSettingsValues } from 'interfaces/shippingCarrier.interface';
import { CustomerId } from 'interfaces/customersId.interface';

type Form = {
  customerName: string;
  city: ICity;
  labelId: string;
  customContent: boolean;
  recipientsAddress: string;
  packageContent: string;
  packages: IPackage[];
  warehouseId: string;
  paymentType: string;
  insuredValue: string;
  serviceLevel: string;
  reference: string;
  valorFleteContraEntrega: string;
  ivaBaseValue: string;
  ivaValue: string;
};

interface Props {
  getOrders(): void;
}
export default function CreateTrackingId({ getOrders }: Props): ReactElement {
  const { openModalCreateShipping, orderToShipping, handleOpenModalCreateShipping } =
    useOrdersContext();

  const { getPaymentTypes } = paymentTypesService();
  const { handleSubmit, register, errors, control, watch, setValue, getValues, reset } =
    useForm<Form>();
  const { jwt, getPaymentTypesByUser } = useUser();
  const [formDirty, setFormDirty] = useState(false);
  const [departamentos, setDepartamentos] = useState<string[]>([]);
  const [departamento, setDepartamento] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<ICity[]>([]);
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const [paymentTypesByUser, setPaymentTypesByUser] = useState<CustomerId[]>([]);
  const [defaultSettings, setDefaultSettings] = useState<IDefaultSettingsValues>();
  const { getCities, getDepartaments, getCitiesByDepartament } = useCities();
  const { getWarehouses } = useWarehouse();
  const { getPackages } = usePackage();
  const { createTrackingId, getDefaultConfiguration } = useShipping();
  const { showNotistack } = useCustomSnackbar();
  const watchPaymentType = watch('paymentType');
  const watchServiceLevel = watch('serviceLevel');
  const watchPackageType = watch('packages');

  const drawProducts = () => {
    const products = orderToShipping.products.map((product, index: number) => (
      <Typography variant="subtitle1" key={index}>
        {product.quantity} x {product.name}
      </Typography>
    ));
    return products;
  };

  const [isCashOnDelivery, setIsCashOnDelivery] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setDepartamento('');
    setCities([]);

    if (orderToShipping) {
      const packageContent = orderToShipping.products.map((p) => p.name).join(', ');

      reset({
        customerName: orderToShipping.recipientsName.trim(),
        reference: orderToShipping.name,
        recipientsAddress: orderToShipping.recipientsAddress,
        packageContent,
      });
    }

    if (warehouses.length === 0) {
      getWarehouses().then((result) => {
        setWarehouses(result);
      });
    }

    getCities()
      .then((citiesResult) => {
        setAllCities(citiesResult);
        setDepartamentos(getDepartaments(citiesResult));
        const cityOfOrder = citiesResult.find(
          (c) => c.nombre === orderToShipping.recipientsCityName
        );
        setDepartamento(cityOfOrder ? cityOfOrder.nombre_departamento : null);
        if (cityOfOrder) {
          setCities(getCitiesByDepartament(citiesResult, cityOfOrder.nombre_departamento));
        }
        setValue('city', cityOfOrder || null);
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (jwt) {
      getPaymentTypes(jwt).then((response) => {
        setPaymentTypes(response);
      });
    }
    getPaymentTypesByUser().then((responsePaymentTypesByUser) => {
      setPaymentTypesByUser(responsePaymentTypesByUser);

      getPackages()
        .then((resultGetPackages) => {
          resultGetPackages.forEach((pack) => {
            const { name, height, width, long, weight } = pack;
            pack.name = `${name} (${height}, ${width}, ${long}, Peso: ${weight})`;
          });
          setPackages(resultGetPackages);

          getDefaultConfiguration().then((response) => {
            if (response) {
              const defaultValues: IDefaultSettingsValues = response.defaultFields.reduce(
                (acc: any, crr: string) => ({ ...acc, [crr]: response.values[crr] }),
                {}
              );
              setDefaultSettings(defaultValues);

              if (defaultValues.insuredValue) {
                setValue(
                  'insuredValue',
                  convertToCurrencyCop(defaultValues.insuredValue.toString())
                );
              }

              if (defaultValues.warehouseId) {
                setValue('warehouseId', defaultValues.warehouseId);
              }

              if (defaultValues.paymentType) {
                const payment = responsePaymentTypesByUser.find(
                  (c) =>
                    c.idCliente === response.values.idCliente &&
                    c.paymentTypeCode === response.values.paymentType
                );
                if (payment) {
                  setValue('paymentType', payment.id);
                }
              }

              if (defaultValues.packages) {
                const packagesToAdd = [];
                for (let index = 0; index < defaultValues.packages.length; index++) {
                  const packageDefault = defaultValues.packages[index];

                  const existPackage = resultGetPackages.find((rp) => rp.id === packageDefault.id);
                  if (existPackage) {
                    packagesToAdd.push({
                      id: existPackage.id,
                      long: existPackage.long,
                      name: existPackage.name,
                      width: existPackage.width,
                      height: existPackage.height,
                      weight: existPackage.weight,
                      quantity: packageDefault.quantity,
                    });
                  }
                }
                setValue('packages', packagesToAdd);
              }

              if (defaultValues.labelId) {
                setValue('labelId', defaultValues.labelId);
              }

              if (defaultValues.serviceLevel) {
                setValue('serviceLevel', defaultValues.serviceLevel);
              }

              if (defaultValues.collectionService?.value) {
                setValue(
                  'valorFleteContraEntrega',
                  convertToCurrencyCop(defaultValues.collectionService.value.toString())
                );
              }

              if (defaultValues.collectionService?.baseValueVat) {
                setValue(
                  'ivaBaseValue',
                  convertToCurrencyCop(defaultValues.collectionService.baseValueVat.toString())
                );
              }

              if (defaultValues.collectionService?.valueVat) {
                setValue(
                  'ivaValue',
                  convertToCurrencyCop(defaultValues.collectionService.valueVat.toString())
                );
              }

              if (response.defaultFields.includes('cashOnDeliveryValue')) {
                setIsCashOnDelivery(true);
                setValue(
                  'valorFleteContraEntrega',
                  convertToCurrencyCop(orderToShipping.totalPrice.toString())
                );
              }
            }
          });
        })
        .catch(() => {
          setPackages([]);
        });
    });
  }, [orderToShipping]);

  const onSubmit = (data: Form) => {
    setFormDirty(true);
    console.log('data', data);

    if (data.packages.length > 0) {
      data.packages.forEach((pack) => {
        if (!pack.quantity) {
          pack.quantity = 1;
        }
      });
    }

    const dataToSend: IShippingRequest = {
      cityCode: data.city.codigo,
      labelId: data.labelId,
      orderId: orderToShipping.orderId,
      packages: data.packages,
      warehouseId: data.warehouseId,
      paymentType: Number(
        paymentTypesByUser.find((c) => c.id === data.paymentType)?.paymentTypeCode
      ),
      idCliente: Number(paymentTypesByUser.find((c) => c.id === data.paymentType)?.idCliente),
      insuredValue: data.insuredValue.replace(/\D/g, ''),
      serviceLevel: Number(data.serviceLevel),
      packageContent: data.packageContent,
    };

    if (dataToSend.serviceLevel === 22) {
      dataToSend.collectionService = {
        reference: data.reference || '',
        value: Number(data.valorFleteContraEntrega?.replace(/\D/g, '')),
        baseValueVat: Number(data.ivaBaseValue?.replace(/\D/g, '')),
        valueVat: Number(data.ivaValue?.replace(/\D/g, '')),
      };
    }
    setIsLoading(true);

    createTrackingId([dataToSend])
      .then((response) => {
        downloadPDF(response.pdf, `pedido_${orderToShipping.orderId}_guia_${response.trackingId}`);
        showNotistack({ message: 'Guía generada correctamente' });
        getOrders();
        handleOpenModalCreateShipping(false);
      })
      .catch((err) => {
        if (err.response?.status === 400 && err.response.data?.message) {
          showNotistack({ message: err.response.data?.message });
        } else {
          showNotistack({
            message:
              typeof err === 'string' ? err : 'Ocurrió un error, contacta con soporte por favor.',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnBlur = (field: string) => {
    const formated = convertToCurrencyCop(getValues(field));
    setValue(field, formated);
  };

  const optionsPaymentTypesByUser = () => {
    return paymentTypesByUser.map((paymentTypeByUser, i) => {
      return (
        <option aria-label={paymentTypeByUser.paymentTypeName} value={paymentTypeByUser.id} key={i}>
          {`${paymentTypeByUser.paymentTypeName} (${paymentTypeByUser.idCliente})`}
        </option>
      );
    });
  };

  return (
    <ModalContainer open={openModalCreateShipping} title="Generar guía" maxWidth="lg">
      <form noValidate onSubmit={handleSubmit<Form>(onSubmit)}>
        <Grid container spacing={2}>
          {/* customerName */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Cliente"
              defaultValue={orderToShipping.recipientsName}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              inputRef={register<HTMLInputElement>({
                required: 'El nombre del destinatario es requerido.',
              })}
              name="customerName"
              error={errors.customerName ? true : false}
              helperText={errors.customerName?.message}
            />
          </Grid>
          {/* name order number with prefix */}
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Pedido"
              value={orderToShipping.name}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
          </Grid>
          {/* departamento */}
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              value={departamento}
              fullWidth
              // getOptionLabel={(option) => option.name}
              onChange={(_, value: string | null) => {
                setCities(allCities.filter((c) => c.nombre_departamento === value));
                setDepartamento(value);
                setCities(getCitiesByDepartament(allCities, value as string));
                setValue('city', null);
              }}
              id="departamento"
              options={departamentos}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Departamento destino"
                  error={formDirty && !departamento}
                  helperText={formDirty && !departamento ? 'Este campo es requerido' : ''}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          {/* city */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="city"
              rules={{
                required: 'Campo requerido.',
              }}
              render={({ value, onChange }) => (
                <Autocomplete
                  value={value || null}
                  fullWidth
                  getOptionLabel={(option) => option.nombre}
                  getOptionSelected={(option) => option && value && option.codigo === value.codigo}
                  onChange={(_, newCity) => onChange(newCity)}
                  id="city"
                  options={cities}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Ciudad destino"
                      error={errors.city ? true : false}
                      helperText={errors.city ? 'Campo requerido.' : ''}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* recipientsAddress */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              label="Dirección de envío"
              defaultValue={orderToShipping.recipientsAddress}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              inputRef={register<HTMLInputElement>({
                required: 'Dirección del destinatario es requerido.',
              })}
              name="recipientsAddress"
              error={errors.recipientsAddress ? true : false}
              helperText={errors.recipientsAddress?.message}
            />
          </Grid>
          {/* label id */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="labelId"
                control={control}
                defaultValue={defaultSettings?.labelId}
                rules={{
                  required: 'Este campo es requerido',
                }}
                as={
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Formato del rótulo"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.labelId ? true : false}
                    helperText={errors.labelId?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona formato del rótulo
                    </option>
                    <option aria-label="44" value="44">
                      Genérico
                    </option>
                    <option aria-label="55" value="55">
                      Genérico 10 x 10
                    </option>
                    <option aria-label="57" value="57">
                      Genérico tercio de página
                    </option>
                    <option aria-label="58" value="58">
                      Genérico media página
                    </option>
                    <option aria-label="59" value="59">
                      Genérico cuarto de página
                    </option>
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {/* Products */}
          <Grid item xs={12} sm={12} md={12}>
            <Card elevation={2}>
              <CardContent>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography variant="subtitle2">Productos</Typography>
                    {drawProducts()}
                    <Typography variant="subtitle2">
                      Valor: {convertToCurrencyCop(orderToShipping.totalPrice.toString())}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography variant="subtitle2">
                      Forma de pago: {orderToShipping.paymentGateway}
                    </Typography>
                    <Typography variant="subtitle2">
                      Ciudad destino: {orderToShipping.recipientsCityName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <TextField
                      label="Contenido"
                      variant="outlined"
                      fullWidth
                      name="packageContent"
                      inputRef={register<HTMLInputElement>({
                        required: 'Debes especificar el contenido',
                      })}
                      InputLabelProps={{ shrink: true }}
                      error={errors.packageContent ? true : false}
                      helperText={errors.packageContent?.message}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card elevation={2}>
              <CardContent>
                <Grid container>
                  {/* packages */}
                  <Grid item xs={12} sm={6} md={6}>
                    <Box m={2}>
                      <FormControl fullWidth variant="outlined">
                        <Controller
                          name="packages"
                          control={control}
                          options={packages}
                          defaultValue={[]}
                          rules={{
                            validate: (value) =>
                              value.length > 0 ? true : 'Este campo es requerido',
                          }}
                          render={(props) => (
                            <TextField
                              select
                              fullWidth
                              label="Seleccione los paquetes"
                              variant="outlined"
                              error={errors.packages ? true : false}
                              helperText={errors.packages ? 'campo requerido' : ''}
                              SelectProps={{
                                multiple: true,
                                value: props.value,
                                onChange: (e) => {
                                  const values: any = e.target.value;
                                  const arr = [];
                                  for (let i = 0; i < values.length; i++) {
                                    const re = [];
                                    for (let j = 0; j < values.length; j++) {
                                      if (values[i].id === values[j].id) {
                                        re.push(i);
                                      }
                                    }
                                    arr.push(re);
                                  }
                                  const unique = arr
                                    .filter((val) => val.length === 1)
                                    .flat()
                                    .map((i) => values[i]);
                                  props.onChange(unique);
                                },
                                renderValue: (s: any) =>
                                  s.length > 0 && s.map((s: { name: string }) => s.name).join(', '),
                              }}
                            >
                              {packages.map((pack: IPackage) => (
                                <MenuItem key={pack.id} value={pack as any}>
                                  <Checkbox
                                    checked={
                                      props.value.map((e: any) => e.id).indexOf(pack.id) > -1
                                    }
                                  />
                                  <ListItemText primary={pack.name} />
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  {watchPackageType?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              {['Paquete', 'Alto', 'Ancho', 'Largo', 'Peso', 'Unidades'].map(
                                (head, i) => (
                                  <TableCell key={i}>{head}</TableCell>
                                )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {watchPackageType.map((pack: IPackage, i: number) => (
                              <TableRow key={pack.name}>
                                <TableCell>{pack.name}</TableCell>
                                <TableCell>{pack.height}</TableCell>
                                <TableCell>{pack.width}</TableCell>
                                <TableCell>{pack.long}</TableCell>
                                <TableCell>{pack.weight}</TableCell>
                                <TableCell style={{ maxWidth: 80 }}>
                                  <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    autoComplete="off"
                                    defaultValue={pack.quantity ? pack.quantity : 1}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      inputProps: {
                                        min: 1,
                                      },
                                    }}
                                    onChange={(e) => {
                                      watchPackageType[i].quantity = Number(e.target.value);
                                      setValue('packages', watchPackageType);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* warehouseId */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="warehouseId"
                control={control}
                defaultValue={defaultSettings?.warehouseId}
                rules={{
                  required: 'Este campo es requerido',
                }}
                as={
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    variant="outlined"
                    label="Bodega origen"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.warehouseId ? true : false}
                    helperText={errors.warehouseId?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona bodega origen
                    </option>
                    {warehouses.map((warehouse) => (
                      <option value={warehouse.id} key={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {/* paymentType */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="paymentType"
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({ onChange, value }) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValue('serviceLevel', '');
                      setValue('reference', '');
                      setValue('valorFleteContraEntrega', '');
                      setValue('ivaBaseValue', '');
                      setValue('ivaValue', '');
                    }}
                    variant="outlined"
                    label="Modalidad de pago"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.paymentType ? true : false}
                    helperText={errors.paymentType?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona modalidad de pago
                    </option>
                    {paymentTypesByUser.length > 0 &&
                      paymentTypes.length > 0 &&
                      optionsPaymentTypesByUser()}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>
          {/* insuredValue */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="insuredValue"
              fullWidth
              label="Valor asegurado (COP)"
              autoComplete="off"
              error={errors.insuredValue ? true : false}
              helperText={errors.insuredValue && errors.insuredValue.message}
              InputLabelProps={{ shrink: true }}
              inputRef={register<HTMLInputElement>({
                required: { value: true, message: 'Este campo es requerido' },
              })}
              onBlur={() => handleOnBlur('insuredValue')}
            />
          </Grid>
          {/* serviceLevel */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="serviceLevel"
                control={control}
                defaultValue={1}
                as={
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    variant="outlined"
                    label="Nivel de servicio"
                    disabled={Number(watchPaymentType) === 6}
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="Estándar" value={1}>
                      Estándar
                    </option>
                    <option aria-label="Recaudo contra entrega" value={22}>
                      Recaudo contra entrega
                    </option>
                    <option aria-label="Recaudo contra entrega" value={26}>
                      Entrega mismo día
                    </option>
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {Number(watchServiceLevel) === 22 && (
            <React.Fragment>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="reference"
                  label="Referencia"
                  autoComplete="reference"
                  inputRef={register<HTMLInputElement>({
                    required: { value: true, message: 'Este campo es requerido' },
                  })}
                  error={errors.reference ? true : false}
                  helperText={errors.reference && errors.reference.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  required
                  variant="outlined"
                  margin="normal"
                  name="valorFleteContraEntrega"
                  fullWidth
                  label="Valor (COP)"
                  autoComplete="off"
                  defaultValue={
                    isCashOnDelivery
                      ? convertToCurrencyCop(orderToShipping.totalPrice.toString())
                      : defaultSettings?.collectionService?.value
                  }
                  error={errors.valorFleteContraEntrega ? true : false}
                  helperText={
                    errors.valorFleteContraEntrega && errors.valorFleteContraEntrega.message
                  }
                  InputLabelProps={{ shrink: true }}
                  inputRef={register<HTMLInputElement>({
                    required: { value: true, message: 'Este campo es requerido' },
                  })}
                  onBlur={() => handleOnBlur('valorFleteContraEntrega')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="ivaBaseValue"
                  fullWidth
                  label="Valor Base IVA (COP)"
                  autoComplete="off"
                  defaultValue={defaultSettings?.collectionService?.baseValueVat}
                  error={errors.ivaBaseValue ? true : false}
                  helperText={errors.ivaBaseValue && errors.ivaBaseValue.message}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register<HTMLInputElement>({})}
                  onBlur={() => handleOnBlur('ivaBaseValue')}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="ivaValue"
                  fullWidth
                  label="Valor IVA (COP)"
                  autoComplete="off"
                  defaultValue={defaultSettings?.collectionService?.valueVat}
                  error={errors.ivaValue ? true : false}
                  helperText={errors.ivaValue && errors.ivaValue.message}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register<HTMLInputElement>({})}
                  onBlur={() => handleOnBlur('ivaValue')}
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid container justifyContent="center" spacing={2}>
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={() => {
                if (Number(watchServiceLevel) !== 22) {
                  setValue('valorFleteContraEntrega', undefined);
                }
                setFormDirty(true);
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : ''} Aceptar
            </Button>
            <Button
              variant="contained"
              color="default"
              disabled={isLoading}
              style={{ marginTop: 20, marginLeft: 20 }}
              onClick={() => handleOpenModalCreateShipping(false)}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalContainer>
  );
}
