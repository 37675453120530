/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  Divider,
} from '@material-ui/core';
import useShippingCarrier from 'hooks/useShippingCarrier';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { DefaultSettingsFields } from 'interfaces/defaultFields.interface';
import { IConfiguration } from 'interfaces/shippingCarrier.interface';
import CustomerIds from '../CustomerIds';
import { CustomerId } from 'interfaces/customersId.interface';
import SameDayDelivery from '../SameDayDelivery';
import { SameDayDelivery as ISameDayDelivery } from 'interfaces/sameDayDelivery.interface';
import useCities from 'hooks/useCities';
import { ICity } from 'interfaces';
import { Autocomplete } from '@material-ui/lab';

export default function ConfigureShippingCarrier(): ReactElement {
  const [isProductionEnvironment, setIsProductionEnvironment] = useState(false);
  const { showNotistack } = useCustomSnackbar();
  const [configurationId, setConfigurationId] = useState<string>();
  const { getConfiguration } = useShippingCarrier();
  const { saveConfiguration } = useShippingCarrier();
  const { handleSubmit, register, errors, reset, setValue, control } = useForm<IConfiguration>();
  const [isLoading, setIsLoading] = useState(false);
  const [customersId, setCustomersId] = useState<CustomerId[]>([]);
  const [sameDayDelivery, setSameDayDelivery] = useState<ISameDayDelivery[]>([]);
  const [defaultSettingsFields, setDefaultSettingsFields] = useState<DefaultSettingsFields>({
    labelId: false,
    packages: false,
    warehouseId: false,
    paymentType: false,
    serviceLevel: false,
    insuredValue: false,
    collectionService: false,
    cashOnDeliveryValue: false,
  });
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [department, setDepartment] = useState<string | null>(null);
  const { getCities, getDepartaments, getCitiesByDepartament } = useCities();

  const onSubmit = (data: IConfiguration) => {
    if (customersId.length === 0) {
      showNotistack({ variant: 'error', message: 'Debe agregar al menos un id cliente' });
      return;
    }

    if (sameDayDelivery.length > 0) {
      data.configurationsSameDayDelivery = sameDayDelivery;
    }

    data.idClientes = customersId;

    setIsLoading(true);
    data.id = configurationId;

    data.defaultSettings = Object.keys(defaultSettingsFields).filter(
      (key: string) => defaultSettingsFields[key]
    );

    data.isProductionEnvironment = isProductionEnvironment;
    saveConfiguration(data)
      .then(() => {
        showNotistack({ message: 'Configuración guardada correctamente' });
      })
      .catch(() => {
        console.log('Ocurrió un error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getConfiguration().then((config: IConfiguration) => {
      setConfigurationId(config.id);

      setIsProductionEnvironment(config.isProductionEnvironment);
      console.log('config', config);
      const defaultSettings = config.defaultSettings.reduce(
        (acc, crr) => ({ ...acc, [crr]: true }),
        {}
      );
      setDefaultSettingsFields(
        config.defaultSettings.length === 0 ? defaultSettingsFields : defaultSettings
      );
      setCustomersId(config.idClientes);
      setSameDayDelivery(config.configurationsSameDayDelivery || []);
      reset(config);
      getCities()
        .then((citiesResult) => {
          setAllCities(citiesResult);
          setDepartments(getDepartaments(citiesResult));
          const citySelected = citiesResult.find((c) => c.nombre === config.city);
          setDepartment(citySelected ? citySelected.nombre_departamento : null);
          if (citySelected) {
            setCities(getCitiesByDepartament(citiesResult, citySelected.nombre_departamento));
          }
          console.log(citySelected);
          setValue('city', citySelected || null);
        })
        .finally(() => setIsLoading(false));
    });
  }, []);

  const handleDefaultFieldsCheck = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { name } = e.target;
    setDefaultSettingsFields((prevState) => {
      if (name === 'collectionService' && checked) {
        return {
          ...prevState,
          collectionService: true,
          cashOnDeliveryValue: false,
        };
      } else if (name === 'cashOnDeliveryValue' && checked) {
        return {
          ...prevState,
          cashOnDeliveryValue: true,
          collectionService: false,
        };
      } else {
        return {
          ...prevState,
          [name]: checked,
        };
      }
    });
  };

  // style={{ marginTop: 40 }}
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* web service */}
      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="h5">Datos de integración web service</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                {/* companyName */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="companyName"
                    label="Razón Social"
                    name="companyName"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Razón Social' es requerido",
                    })}
                    error={errors.companyName ? true : false}
                    helperText={errors.companyName?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* phoneNumber */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="phoneNumber"
                    label="Número de teléfono"
                    name="phoneNumber"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: 'Este Campo es requerido',
                    })}
                    error={errors.phoneNumber ? true : false}
                    helperText={errors.phoneNumber?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* nit */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="nit"
                    label="NIT"
                    name="nit"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'NIT' es requerido",
                    })}
                    error={errors.nit ? true : false}
                    helperText={errors.nit?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* userName */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="userName"
                    label="Usuario"
                    name="userName"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Usuario' es requerido",
                    })}
                    error={errors.userName ? true : false}
                    helperText={errors.userName?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* userPassword */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="userPassword"
                    label="Contraseña"
                    name="userPassword"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Contraseña' es requerido",
                    })}
                    error={errors.userPassword ? true : false}
                    helperText={errors.userPassword?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* apiKey */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="apiKey"
                    label="Api Key"
                    name="apiKey"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Api key' es requerido",
                    })}
                    error={errors.apiKey ? true : false}
                    helperText={errors.apiKey?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* apiKeyPassword */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="apiKeyPassword"
                    label="Contraseña Api key"
                    name="apiKeyPassword"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Contraseña Api key' es requerido",
                    })}
                    error={errors.apiKeyPassword ? true : false}
                    helperText={errors.apiKeyPassword?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* div */}
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="div"
                    label="Div"
                    name="div"
                    autoComplete="off"
                    disabled={isLoading}
                    inputRef={register<HTMLInputElement>({
                      required: "Campo 'Div' es requerido",
                    })}
                    error={errors.div ? true : false}
                    helperText={errors.div?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* isProductionEnvironment */}
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isProductionEnvironment}
                        onChange={(e) => setIsProductionEnvironment(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Producción: off"
                    labelPlacement="start"
                  />
                  <label className="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementStart">
                    <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
                      {' '}
                      on
                    </span>
                  </label>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    value={department}
                    fullWidth
                    // getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option && value && option === value ? true : false
                    }
                    onChange={(_, value) => {
                      setDepartment(value);
                      setCities(getCitiesByDepartament(allCities, value as string));
                      setValue('city', null);
                    }}
                    id="departamento"
                    options={departments}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Departamento de la cuenta"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {/* city */}
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    control={control}
                    name="city"
                    rules={{
                      required: 'Campo requerido.',
                    }}
                    render={({ value, onChange }) => (
                      <Autocomplete
                        value={value || null}
                        fullWidth
                        getOptionLabel={(option) => option.nombre}
                        getOptionSelected={(option) =>
                          option && value && option.nombre === value.nombre
                        }
                        onChange={(_, newCity) => onChange(newCity)}
                        id="city"
                        options={cities}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label="Ciudad de la cuenta"
                            error={errors.city ? true : false}
                            helperText={errors.city ? 'Campo requerido.' : ''}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 40 }} />
      {/* CustomerIds */}
      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="h5">Id clientes</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={10}>
                  <CustomerIds
                    setCustomerIds={(data: CustomerId[]) => {
                      setCustomersId(data);
                    }}
                    customerIds={customersId}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 40 }} />
      {/* SameDayDelivery */}
      {/* TODO: revisar bug al eliminar una configuración por que se elimina todo. */}
      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="h5">Entrega el mismo día</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={10}>
                  <SameDayDelivery
                    setSameDayDelivery={(data: ISameDayDelivery[]) => {
                      setSameDayDelivery(data);
                    }}
                    sameDayDelivery={sameDayDelivery}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 40 }} />
      {/* defaultSettings */}
      <Grid container style={{ marginTop: 40 }}>
        <Grid item xs={12} md={4} lg={3}>
          <Typography variant="h5">Campos predefinidos</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item md={6}>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="labelId"
                            checked={defaultSettingsFields.labelId}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Formato de rótulo"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="packages"
                            checked={defaultSettingsFields.packages}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Tipo de paquetes"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="warehouseId"
                            checked={defaultSettingsFields.warehouseId}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Bodega de origen"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="paymentType"
                            checked={defaultSettingsFields.paymentType}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Modalidad de pago"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="insuredValue"
                            checked={defaultSettingsFields.insuredValue}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Valor asegurado"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="serviceLevel"
                            checked={defaultSettingsFields.serviceLevel}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Nivel de servicio"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="collectionService"
                            checked={defaultSettingsFields.collectionService}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Valor a Recaudar (RCE)"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="cashOnDeliveryValue"
                            checked={defaultSettingsFields.cashOnDeliveryValue}
                            onChange={handleDefaultFieldsCheck}
                            color="primary"
                          />
                        }
                        label="Valor a recaudar contraentrega y envío"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ marginTop: 20 }}
        >
          {isLoading && <CircularProgress size={24} />}Guardar
        </Button>
      </Grid>
    </form>
  );
}
