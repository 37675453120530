import React, { ReactElement, useState, useEffect } from 'react';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import SchedulePickupForm from 'components/organisms/SchedulePickupForm';
import useSchedulePickup from 'hooks/useSchedulePickup';
import useWarehouse from 'hooks/useWarehouse';
import { IWarehousePickup } from 'interfaces/warehouse.interface';

export default function SchedulePickup(): ReactElement {
  const [warehouses, setWareHouses] = useState<IWarehousePickup[]>([]);

  const { addSchedulePickup } = useSchedulePickup();
  const { getWarehouses } = useWarehouse();

  const getAllWareHouses = () => {
    getWarehouses().then((resultwareHouses: any) => {
      setWareHouses(resultwareHouses);
    });
  };

  useEffect(() => {
    getAllWareHouses();
  }, []);

  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" component="h1" align="center">
            Crear solicitud de recogida
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <Card elevation={2}>
            <CardContent>
              <SchedulePickupForm warehouses={warehouses} handleOnSubmit={addSchedulePickup} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminTemplate>
  );
}
