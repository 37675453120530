import axios from 'axios';
import { URL_PAYMENT_TYPES } from 'constants/url';
import { PaymentType } from 'interfaces/paymentTypes.interface';

export default function paymentTypesService() {
  const getPaymentTypes = (jwt: string): Promise<PaymentType[]> => {
    return axios
      .get<PaymentType[]>(URL_PAYMENT_TYPES, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        return response.data;
      });
  };
  return { getPaymentTypes };
}
