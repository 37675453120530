import React, { useState } from 'react';
import axios from 'axios';
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import logo from 'assets/img/logo.png';
import useStyles from './recover-password.styles';
import { isEmail } from 'utils/validator';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { URL_FRONT, URL_RECOVER_PASSWORD } from 'constants/url';

export default function RecoverPassword() {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const { showNotistack } = useCustomSnackbar();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError('Debes ingresar tu email');
      return;
    }
    if (!isEmail(email)) {
      setError('Email no válido');
      return;
    }
    try {
      setIsLoading(true);
      const response = await axios.post(URL_RECOVER_PASSWORD, {
        email: email,
        redirectUrl: `${URL_FRONT}/change-password`,
      });
      if (response.status === 201) {
        showNotistack({ message: response.data.message });
      }
    } catch (err) {
      if (err.request.status === 400) {
        const msg = Array.isArray(err.response.data?.message)
          ? err.response.data?.message[0]
          : 'Error no identificado';
        setError(msg);
      } else {
        showNotistack({ message: 'Ocurrió un error, contactar con soporte' });
      }
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container justifyContent="center">
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <img src={logo} className="App-logo" alt="logo" width="400" />
          </Grid>
          <Grid item md={12} style={{ textAlign: 'center' }}>
            <Typography component="h1" variant="h5" style={{ marginTop: 40 }}>
              Aquí puedes recuperar tu contraseña.
            </Typography>
            <Typography component="h2" variant="subtitle2" style={{ margin: 10 }}>
              Enviaremos un email con un link que te permitirá restablecer tu contraseña
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
            <form noValidate onSubmit={onSubmit}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="email"
                  label="Ingresa tu email"
                  name="email"
                  autoComplete="email"
                  fullWidth
                  disabled={isLoading}
                  error={error ? true : false}
                  helperText={error}
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : ''} Enviar enlace de recuperación
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
