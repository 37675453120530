import { getAllOrders, OrdersResponse } from 'services/ordersService';
import useUser from 'hooks/useUser';
import { Pagination } from 'interfaces/pagination.interface';
import { OrdersFiltersData } from 'interfaces/order.interface';

export default function useOrders() {
  const { jwt } = useUser();

  const getOrders = async (
    pagination: Pagination,
    filters?: OrdersFiltersData
  ): Promise<OrdersResponse> => {
    return getAllOrders({ jwt, pagination, filters });
  };

  return {
    getOrders,
  };
}
