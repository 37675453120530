import React, { ReactElement, useState, useEffect } from 'react';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Grid, Typography } from '@material-ui/core';
import WarehouseList from 'components/organisms/WarehouseList';
import ModalContainer from 'components/molecules/ModalContainer';
import WarehouseForm from 'components/organisms/WarehouseForm';
import useWarehouse from 'hooks/useWarehouse';
import WarehouseDeleteModal from 'components/organisms/WarehouseDeleteModal';
import { IWarehouse } from 'interfaces/warehouse.interface';

export default function Warehouse(): ReactElement {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [warehouseId, setWarehouseId] = useState('');
  const { getWarehouses, addWarehouse, updateWarehouse } = useWarehouse();

  const handleOnCloseModal = () => {
    setOpenModal(false);
  };

  const getAllWarehouses = () => {
    getWarehouses().then((result: IWarehouse[]) => {
      if (result.length > 0) {
        setWarehouses(result);
      }
    });
  };

  const saveWarehouse = (data: IWarehouse) => {
    if (data.id) {
      return updateWarehouse(data);
    } else {
      return addWarehouse(data);
    }
  };
  const addButton = () => {
    setWarehouseId('');
    setOpenModal(true);
  };

  const handleOnClickEditPackage = (warehouseId: string) => {
    setWarehouseId(warehouseId);
    setOpenModal(true);
  };

  const handleOnClickDeletePackage = (warehouseId: string) => {
    setWarehouseId(warehouseId);
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    getAllWarehouses();
  }, []);

  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h5" component="h1" align="center">
            Gestión de Bodegas
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <WarehouseList
            isLoading={false}
            warehouses={warehouses}
            addButton={addButton}
            handleOnClickEditPackage={handleOnClickEditPackage}
            handleOnClickDeletePackage={handleOnClickDeletePackage}
          />
        </Grid>
      </Grid>
      <ModalContainer title="Crear bodega" open={openModal} onClose={handleOnCloseModal}>
        <WarehouseForm
          handleCloseModal={handleOnCloseModal}
          handleOnSubmit={saveWarehouse}
          warehouseId={warehouseId}
          getAllWarehouses={getAllWarehouses}
        />
      </ModalContainer>

      <WarehouseDeleteModal
        onClose={() => {
          setOpenDeleteModal(!openDeleteModal);
        }}
        open={openDeleteModal}
        warehouseId={warehouseId}
        getAllWarehouses={getAllWarehouses}
      ></WarehouseDeleteModal>
    </AdminTemplate>
  );
}
