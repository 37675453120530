/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  IconButton,
  Typography,
  FormLabel,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '@material-ui/icons/Add';
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from 'react-hook-form';
import { ICity, IWarehouse } from 'interfaces';
import { Autocomplete } from '@material-ui/lab';
import useCities from 'hooks/useCities';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { SameDayDelivery as ISameDayDelivery } from 'interfaces/sameDayDelivery.interface';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import convertToCurrencyCop from 'utils/convertToCurrencyCop';
import useWarehouses from 'hooks/useWarehouse';

type StateForm = {
  additionalValue: number;
  city: any;
  department: string | null;
  hourToSameDayDelivery: Date | null;
  warehouseId: string;
};

type Props = {
  setSameDayDelivery: (data: ISameDayDelivery[]) => void;
  sameDayDelivery: ISameDayDelivery[];
};

const SameDayDelivery: React.FC<Props> = ({ sameDayDelivery, setSameDayDelivery }) => {
  const { register, errors, control, handleSubmit, setValue, setError } = useForm<StateForm>();
  const [cities, setCities] = useState<ICity[]>([]);
  const { getCities, getDepartaments, getCitiesByDepartament } = useCities();
  const [departments, setDepartments] = useState<string[]>([]);
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const { getWarehouses } = useWarehouses();

  useEffect(() => {
    getCities().then((citiesResult) => {
      setAllCities(citiesResult);
      setDepartments(getDepartaments(citiesResult));
      setValue('city', null);
    });
    handleGetWarehouses();
  }, []);

  const handleGetWarehouses = () => {
    getWarehouses()
      .then((warehousesResponse) => {
        setWarehouses(warehousesResponse);
      })
      .catch(console.log);
  };

  const onSubmit = (data: StateForm) => {
    if (
      sameDayDelivery.some(
        (c) => c.cityCode === data.city.codigo && c.warehouseId === data.warehouseId
      )
    ) {
      setError('city', { message: 'Ciudad y Bodega ya existe' });
      setError('warehouseId', { message: 'Ciudad y Bodega ya existe' });
      return;
    }
    // eslint-disable-next-line no-debugger
    const sameDayDeliveryToAdd: ISameDayDelivery = {
      additionalValue: data.additionalValue,
      cityCode: data.city.codigo,
      hourToSameDayDelivery: data.hourToSameDayDelivery || new Date(),
      warehouseId: data.warehouseId,
    };

    setSameDayDelivery([...sameDayDelivery, sameDayDeliveryToAdd]);

    setValue('additionalValue', '');
    setValue('department', null);
    setValue('city', null);
    setValue('hourToSameDayDelivery', null);
    setValue('warehouseId', '');
  };

  const handleDelete = (sameDayDeliveryToDelete: ISameDayDelivery) => {
    const newSameDayDelivery = sameDayDelivery.filter(
      (c) => c.cityCode !== sameDayDeliveryToDelete.cityCode
    );
    setSameDayDelivery([...newSameDayDelivery]);
  };

  const tableRows = () => {
    return sameDayDelivery.map((ci) => (
      <TableRow key={ci.cityCode}>
        <TableCell padding="none">{convertToCurrencyCop(ci.additionalValue.toString())}</TableCell>
        <TableCell padding="none">
          {warehouses.find((w) => w.id === ci.warehouseId)?.name}
        </TableCell>
        <TableCell padding="none">
          {allCities.find((c) => c.codigo === ci.cityCode)?.nombre}
        </TableCell>
        <TableCell padding="none">
          {typeof ci.hourToSameDayDelivery === 'string'
            ? format(parseISO(ci.hourToSameDayDelivery), 'hh:mm aa')
            : format(ci.hourToSameDayDelivery, 'hh:mm aa')}
        </TableCell>
        <TableCell padding="none">
          <IconButton onClick={() => handleDelete(ci)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          {/* additionalValue */}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="dense"
              id="additionalValue"
              label="Valor adicional"
              name="additionalValue"
              type="number"
              autoComplete="off"
              inputRef={register<HTMLInputElement>({
                required: 'Campo requerido',
                pattern: {
                  value: /^[+]?\d+(\d+)?$/,
                  message: 'Valor no válido',
                },
              })}
              error={errors.additionalValue ? true : false}
              helperText={errors.additionalValue?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {/* department */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="department"
              rules={{
                required: 'Campo requerido.',
              }}
              defaultValue={null}
              render={({ value, onChange }) => (
                <Autocomplete
                  value={value || null}
                  fullWidth
                  getOptionSelected={(option) => option && value && option === value}
                  onChange={(_, newDepartment) => {
                    onChange(newDepartment);
                    if (newDepartment) {
                      setCities(getCitiesByDepartament(allCities, newDepartment));
                    } else {
                      setCities([]);
                      setValue('city', null);
                    }
                  }}
                  id="department"
                  options={departments}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="dense"
                      label="Departamento destino"
                      error={errors.department ? true : false}
                      helperText={errors.department ? 'Campo requerido.' : ''}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* city */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="city"
              rules={{
                required: 'Campo requerido.',
              }}
              defaultValue={null}
              render={({ value, onChange }) => (
                <Autocomplete
                  value={value || null}
                  fullWidth
                  getOptionLabel={(option) => option.nombre}
                  getOptionSelected={(option) => option && value && option.codigo === value.codigo}
                  onChange={(_, newCity) => onChange(newCity)}
                  id="city"
                  options={cities}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      margin="dense"
                      label="Ciudad destino"
                      error={errors.city ? true : false}
                      helperText={errors.city?.message}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* warehouse */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="warehouseId"
              rules={{
                required: 'Campo requerido.',
              }}
              defaultValue={null}
              render={({ value, onChange }) => (
                <TextField
                  name="labelId"
                  fullWidth
                  select
                  margin="dense"
                  variant="outlined"
                  label="Bodega de origen"
                  SelectProps={{
                    native: true,
                  }}
                  error={Boolean(errors.warehouseId)}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value || '');
                  }}
                  helperText={errors.warehouseId?.message}
                  InputLabelProps={{ shrink: true }}
                >
                  <option>Selecciona una bodega</option>
                  {warehouses.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          {/* hourToSameDayDelivery */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="hourToSameDayDelivery"
              rules={{
                required: 'Campo requerido.',
              }}
              defaultValue={null}
              render={({ value, onChange }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormControl fullWidth>
                    <TimePicker
                      fullWidth
                      margin="dense"
                      id="time-picker"
                      label="Hora límite"
                      value={value || null}
                      onChange={(date: Date | null) => onChange(date)}
                      error={errors.hourToSameDayDelivery ? true : false}
                      inputVariant="outlined"
                    />
                    <FormLabel error={errors.hourToSameDayDelivery ? true : false}>
                      <Typography variant="caption">
                        {errors.hourToSameDayDelivery?.message}
                      </Typography>
                    </FormLabel>
                  </FormControl>
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={4}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: 9 }}
              onClick={handleSubmit(onSubmit)}
              fullWidth
              startIcon={<PlusIcon />}
            >
              Agregar
            </Button>
          </Grid>
          {/* table */}
          <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Valor adicional</TableCell>
                  <TableCell>Bodega</TableCell>
                  <TableCell>Ciudad destino</TableCell>
                  <TableCell>Hora límite</TableCell>
                  <TableCell>Eliminar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableRows()}</TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SameDayDelivery;
