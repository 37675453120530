import axios from 'axios';
import { ACCESS_TOKEN_NAME, USER_KEY_STORE } from 'constants/securityConts';

export default () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (!error.response && !error.status) {
        //   showNotification('Ocurrió un error', 'error');
        return Promise.reject(error);
      }
      switch (error.response.status) {
        case 400:
          break;
        case 401:
          window.localStorage.removeItem(ACCESS_TOKEN_NAME);
          window.localStorage.removeItem(USER_KEY_STORE);

          window.location.href = '/login';
          console.log('--> 401');
          break;
        case 403:
          // showNotification('(403) No estas autorizado para esta acción', 'error');
          break;
        case 404:
          // showNotification('(404) El recurso no existe', 'error');
          break;
        case 405:
          // showNotification('(405) Método no permitido', 'error');
          break;
        case 415:
          // showNotification('(415) Media type no soportado', 'error');
          break;
        case 500:
          // showNotification('(500) Ocurrió un error en el servidor', 'error');
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );
};
