import React, { ReactElement, useEffect, useState } from 'react';
import ModalContainer from 'components/molecules/ModalContainer';
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import useWarehouse from 'hooks/useWarehouse';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { IWarehouse } from 'interfaces/warehouse.interface';

interface Props {
  open: boolean;
  warehouseId: string;
  onClose(): void;
  getAllWarehouses(): void;
}

export default function WarehouseDeleteModal({
  open,
  onClose,
  warehouseId,
  getAllWarehouses,
}: Props): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [warehouseToDelete, setPackageToDelete] = useState<IWarehouse>();
  const { getWarehouseById, deleteWarehouse } = useWarehouse();
  const { showNotistack } = useCustomSnackbar();

  useEffect(() => {
    if (warehouseId) {
      setIsLoading(true);
      getWarehouseById(warehouseId)
        .then((resultPackage: IWarehouse) => {
          setPackageToDelete(resultPackage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [warehouseId]);

  const handleOnClick = () => {
    deleteWarehouse(warehouseId).then((response) => {
      if (response === true) {
        showNotistack({ message: 'El bodega se eliminó correctamente' });
        getAllWarehouses();
        onClose();
      } else {
        showNotistack({ message: 'Ocurrió un error al eliminar el bodega' });
      }
    });
  };
  return (
    <ModalContainer open={open} title="Eliminar bodega">
      <Typography variant="h5">
        {`Estas seguro que deseas eliminar el registro: '${warehouseToDelete?.name}'?`}
      </Typography>
      <Grid container justifyContent="center">
        <Button
          onClick={handleOnClick}
          variant="contained"
          color="primary"
          disabled={isLoading}
          style={{ marginTop: 20 }}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Si
        </Button>
        <Button
          variant="contained"
          color="default"
          disabled={isLoading}
          style={{ marginTop: 20, marginLeft: 20 }}
          onClick={onClose}
        >
          {isLoading ? <CircularProgress size={24} /> : ''} Cancelar
        </Button>
      </Grid>
    </ModalContainer>
  );
}
