import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    minHeight: 380,
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
}));
