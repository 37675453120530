import {
  UPDATE_ORDERS,
  SET_ORDER_TO_SHIPPING,
  UPDATE_ISlOADING_ORDERS,
  OPEN_MODAL_CREATE_SHIPPING,
} from 'actions/orders.actions';
import { IOrderToShipping, IOrder, IShipping } from 'interfaces';

export type IActionOrdersReducer =
  | { type: typeof UPDATE_ISlOADING_ORDERS; payload: boolean }
  | { type: typeof SET_ORDER_TO_SHIPPING; payload: IOrderToShipping }
  | { type: typeof OPEN_MODAL_CREATE_SHIPPING; payload: boolean }
  | {
      type: typeof UPDATE_ORDERS;
      payload: { isLoading: boolean; orders: IOrder[]; total: number; totalPages: number };
    };

export interface IOrderState {
  isLoadingOrders: boolean;
  totalOrders: number;
  totalPages: number;
  orders: IOrder[];
  openModalCreateShipping: boolean;
  orderToShipping: IOrderToShipping;
  shipping: IShipping;
}
export const initialStateOrdersReducer: IOrderState = {
  isLoadingOrders: false,
  orders: [],
  totalOrders: 0,
  totalPages: 1,
  openModalCreateShipping: false,
  orderToShipping: {
    orderId: 0,
    orderNumber: 0,
    recipientsName: '',
    recipientsAddress: '',
    recipientsProvinceName: '',
    recipientsCityName: '',
    products: [],
    totalPrice: 0,
    name: '',
    paymentGateway: '',
  },
  shipping: {
    insuredValue: '',
    cityCode: '',
    orderId: '',
    packages: [
      {
        name: '',
        height: 0,
        width: 0,
        long: 0,
        weight: 0,
      },
    ],
    warehouseId: '',
    labelId: '',
    paymentType: 0,
    serviceLevel: 0,
    valorFleteContraEntrega: '',
    customerName: '',
    recipientsAddress: '',
    packageContent: '',
  },
};

export default function ordersReducer(
  state: IOrderState,
  action: IActionOrdersReducer
): IOrderState {
  switch (action.type) {
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        totalOrders: action.payload.total,
        totalPages: action.payload.totalPages,
        isLoadingOrders: action.payload.isLoading,
      };
    case UPDATE_ISlOADING_ORDERS:
      return { ...state, isLoadingOrders: action.payload };
    case SET_ORDER_TO_SHIPPING:
      return { ...state, orderToShipping: action.payload, openModalCreateShipping: true };
    case OPEN_MODAL_CREATE_SHIPPING:
      return { ...state, openModalCreateShipping: action.payload };
    default:
      return state;
  }
}
