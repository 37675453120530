/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ModalContainer from 'components/molecules/ModalContainer';
import { IOrder } from 'interfaces/order.interface';
import useWarehouse from 'hooks/useWarehouse';
import useCities from 'hooks/useCities';
import useUser from 'hooks/useUser';
import usePackage from 'hooks/usePackage';
import useShipping from 'hooks/useShippingCarrier';
import paymentTypesService from 'services/paymentTypesService';
import { ICity } from 'interfaces/city.interface';
import { Controller, useForm } from 'react-hook-form';
import { IPackage } from 'interfaces/shippingPackage.interface';
import { IDefaultSettingsValues } from 'interfaces/shippingCarrier.interface';
import { IWarehouse } from 'interfaces/warehouse.interface';
import { PaymentType } from 'interfaces/paymentTypes.interface';
import { IShippingRequest } from 'interfaces/shipping.interface';
import { downloadPDF } from 'utils/downloadPDF';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import convertToCurrencyCop from 'utils/convertToCurrencyCop';
import { CustomerId } from 'interfaces/customersId.interface';

type Form = {
  city: ICity;
  labelId: string;
  customContent: boolean;
  packageContent: string;
  packages: IPackage[];
  warehouseId: string;
  paymentType: string;
  insuredValue: string;
  serviceLevel: string;
};

type Props = {
  openModal: boolean;
  closeModal: () => void;
  orders: IOrder[];
  getOrders(): void;
};

export default function CreateMultipleShippingModal({
  openModal,
  closeModal,
  orders,
  getOrders,
}: Props) {
  const { control, setValue, register, errors, watch, handleSubmit, getValues } = useForm<Form>();
  const { getWarehouses } = useWarehouse();
  const { getPaymentTypes } = paymentTypesService();
  const { jwt, getPaymentTypesByUser } = useUser();
  const { getCities, getDepartaments, getCitiesByDepartament } = useCities();
  const { createTrackingId, getDefaultConfiguration } = useShipping();
  const { showNotistack } = useCustomSnackbar();
  const [departments, setDepartments] = useState<string[]>([]);
  const [department, setDepartment] = useState<string | null>(null);
  const [allCities, setAllCities] = useState<ICity[]>([]);
  const [defaultSettings, setDefaultSettings] = useState<IDefaultSettingsValues>();
  const [cities, setCities] = useState<ICity[]>([]);
  const [packages, setPackages] = useState<IPackage[]>([]);
  const { getPackages } = usePackage();
  const [warehouses, setWarehouses] = useState<IWarehouse[]>([]);
  const [paymentTypesByUser, setPaymentTypesByUser] = useState<CustomerId[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const showCustomContent = watch('customContent');
  const watchPackageType = watch('packages');

  useEffect(() => {
    setIsLoading(true);
    getCities()
      .then((citiesResult) => {
        setAllCities(citiesResult);
        setDepartments(getDepartaments(citiesResult));
        const cityOfOrders = citiesResult.find((c) => c.nombre === orders[0].shipping.city);
        setDepartment(cityOfOrders ? cityOfOrders.nombre_departamento : null);
        if (cityOfOrders) {
          setCities(getCitiesByDepartament(citiesResult, cityOfOrders.nombre_departamento));
        }
        setValue('city', cityOfOrders || null);
      })
      .finally(() => setIsLoading(false));

    if (jwt) {
      getPaymentTypes(jwt).then((response) => {
        setPaymentTypes(response);
      });
    }

    getPaymentTypesByUser().then((responsePaymentTypesByUser) => {
      setPaymentTypesByUser(responsePaymentTypesByUser);
      getPackages()
        .then((resultGetPackages) => {
          resultGetPackages.forEach((pack) => {
            const { name, height, width, long, weight } = pack;
            pack.name = `${name} (${height}, ${width}, ${long}, Peso: ${weight})`;
          });
          setPackages(resultGetPackages);

          getDefaultConfiguration().then((response) => {
            if (response) {
              const defaultValues: IDefaultSettingsValues = response.defaultFields.reduce(
                (acc: any, crr: string) => {
                  return { ...acc, [crr]: response.values[crr] };
                },
                {}
              );

              if (defaultValues.paymentType) {
                const payment = responsePaymentTypesByUser.find(
                  (c) =>
                    c.idCliente === response.values.idCliente &&
                    c.paymentTypeCode === response.values.paymentType
                );
                if (payment) {
                  setValue('paymentType', payment.id);
                }
              }

              setDefaultSettings(defaultValues);

              if (defaultValues.insuredValue) {
                setValue(
                  'insuredValue',
                  convertToCurrencyCop(defaultValues.insuredValue.toString())
                );
              }

              if (defaultValues.packages) {
                const packagesToAdd = [];
                for (let index = 0; index < defaultValues.packages.length; index++) {
                  const packageDefault = defaultValues.packages[index];

                  const existPackage = resultGetPackages.find((rp) => rp.id === packageDefault.id);
                  if (existPackage) {
                    packagesToAdd.push({
                      id: existPackage.id,
                      long: existPackage.long,
                      name: existPackage.name,
                      width: existPackage.width,
                      height: existPackage.height,
                      weight: existPackage.weight,
                      quantity: packageDefault.quantity,
                    });
                  }
                }
                setValue('packages', packagesToAdd);
              }

              if (defaultValues.warehouseId) {
                setValue('warehouseId', defaultValues.warehouseId);
              }

              if (defaultValues.labelId) {
                setValue('labelId', defaultValues.labelId);
              }
            }
          });
        })
        .catch(() => {
          setPackages([]);
        });
    });

    if (warehouses.length === 0) {
      getWarehouses().then((result) => {
        setWarehouses(result);
      });
    }
  }, []);

  const optionsPaymentTypesByUser = () => {
    return paymentTypesByUser.map((paymentTypeByUser) => {
      return (
        <option
          aria-label={paymentTypeByUser.paymentTypeName}
          value={paymentTypeByUser.id}
          key={paymentTypeByUser.paymentTypeCode}
        >
          {`${paymentTypeByUser.paymentTypeName} (${paymentTypeByUser.idCliente})`}
        </option>
      );
    });
  };

  const handleOnBlur = (field: string) => {
    const formated = convertToCurrencyCop(getValues(field));
    setValue(field, formated);
  };

  const onSubmit = (data: Form) => {
    console.log(data);
    setIsLoading(true);
    if (data.packages.length > 0) {
      data.packages.forEach((pack) => {
        if (!pack.quantity) {
          pack.quantity = 1;
        }
      });
    }

    const dataToSend: IShippingRequest[] = orders.map((order) => ({
      cityCode: data.city.codigo,
      labelId: data.labelId,
      orderId: order.id,
      packages: data.packages,
      warehouseId: data.warehouseId,
      paymentType: Number(
        paymentTypesByUser.find((c) => c.id === data.paymentType)?.paymentTypeCode
      ),
      idCliente: Number(paymentTypesByUser.find((c) => c.id === data.paymentType)?.idCliente),
      insuredValue: data.insuredValue?.replace(/\D/g, ''),
      serviceLevel: Number(data.serviceLevel),
      packageContent: data.customContent
        ? data.packageContent
        : order.line_items.map((product) => `${product.quantity} ${product.name}`).join(', '),
    }));

    createTrackingId(dataToSend)
      .then((response) => {
        downloadPDF(response.pdf, `guias_masivas`);
        showNotistack({ message: 'Guías generadas correctamente' });
        getOrders();
        closeModal();
      })
      .catch((err) => {
        if (err.response?.status === 400 && err.response.data?.message) {
          showNotistack({ message: err.response.data?.message });
        } else {
          showNotistack({
            message:
              typeof err === 'string' ? err : 'Ocurrió un error, contacta con soporte por favor.',
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ModalContainer open={openModal} title="Generar guía masivas" maxWidth="lg">
      <form noValidate onSubmit={handleSubmit<Form>(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" my={2}>
              <Typography
                variant="h5"
                component="h2"
                style={{ fontWeight: 'bold', marginRight: '10px' }}
              >
                Pedidos:
              </Typography>
              {orders.map((o) => (
                <Chip
                  label={o.number}
                  key={o.number}
                  color="primary"
                  variant="outlined"
                  style={{ marginLeft: '10px' }}
                />
              ))}
            </Box>
          </Grid>
          {/* departamento */}
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              value={department}
              fullWidth
              // getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) =>
                option && value && option === value ? true : false
              }
              onChange={(_, value) => {
                setDepartment(value);
                setCities(getCitiesByDepartament(allCities, value as string));
                setValue('city', null);
              }}
              id="departamento"
              options={departments}
              renderInput={(params) => (
                <TextField {...params} required label="Departamento destino" variant="outlined" />
              )}
            />
          </Grid>
          {/* city */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="city"
              rules={{
                required: 'Campo requerido.',
              }}
              render={({ value, onChange }) => (
                <Autocomplete
                  value={value || null}
                  fullWidth
                  getOptionLabel={(option) => option.nombre}
                  getOptionSelected={(option) => option && value && option.codigo === value.codigo}
                  onChange={(_, newCity) => onChange(newCity)}
                  id="city"
                  options={cities}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Ciudad destino"
                      error={errors.city ? true : false}
                      helperText={errors.city ? 'Campo requerido.' : ''}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* label id */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="labelId"
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                as={
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Formato del rótulo"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.labelId ? true : false}
                    helperText={errors.labelId?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona formato del rótulo
                    </option>
                    <option aria-label="44" value="44">
                      Genérico
                    </option>
                    <option aria-label="55" value="55">
                      Genérico 10 x 10
                    </option>
                    <option aria-label="57" value="57">
                      Genérico tercio de página
                    </option>
                    <option aria-label="58" value="58">
                      Genérico media página
                    </option>
                    <option aria-label="59" value="59">
                      Genérico cuarto de página
                    </option>
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {/* customContent */}
          <Grid item xs={12}>
            <Box display="flex" my={4} style={{ marginLeft: '0px', height: '60px' }}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    color="primary"
                    name="customContent"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Contenido personalizado"
              />
              {showCustomContent && (
                <TextField
                  label="Contenido"
                  variant="outlined"
                  name="packageContent"
                  inputRef={register<HTMLInputElement>({
                    required: 'Debes especificar el contenido',
                  })}
                  error={errors.packageContent ? true : false}
                  helperText={errors.packageContent?.message}
                  style={{ flexGrow: 1 }}
                />
              )}
            </Box>
          </Grid>
          {/* packages */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <Box my={2}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="packages"
                      control={control}
                      options={packages}
                      defaultValue={[]}
                      rules={{
                        validate: (value) => (value.length > 0 ? true : false),
                      }}
                      render={(props) => (
                        <TextField
                          select
                          fullWidth
                          label="Seleccione los paquetes a enviar"
                          variant="outlined"
                          error={errors.packages ? true : false}
                          helperText={errors.packages ? 'Este campo es requerido' : ''}
                          SelectProps={{
                            multiple: true,
                            value: props.value,
                            onChange: (e) => {
                              const values: any = e.target.value;
                              const arr = [];
                              for (let i = 0; i < values.length; i++) {
                                const re = [];
                                for (let j = 0; j < values.length; j++) {
                                  if (values[i].id === values[j].id) {
                                    re.push(i);
                                  }
                                }
                                arr.push(re);
                              }
                              const unique = arr
                                .filter((val) => val.length === 1)
                                .flat()
                                .map((i) => values[i]);
                              props.onChange(unique);
                            },
                            renderValue: (s: any) =>
                              s.length > 0 && s.map((s: { name: string }) => s.name).join(', '),
                          }}
                        >
                          {packages.map((pack: IPackage) => (
                            <MenuItem key={pack.id} value={pack as any}>
                              <Checkbox
                                checked={props.value.map((e: any) => e.id).indexOf(pack.id) > -1}
                              />
                              <ListItemText primary={pack.name} />
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </FormControl>
                </Box>
              </Grid>
              {watchPackageType?.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {['Paquete', 'Alto', 'Ancho', 'Largo', 'Peso', 'Unidades'].map(
                            (head, i) => (
                              <TableCell key={i}>{head}</TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watchPackageType.map((pack: IPackage, i: number) => (
                          <TableRow key={i}>
                            <TableCell>{pack.name}</TableCell>
                            <TableCell>{pack.height}</TableCell>
                            <TableCell>{pack.width}</TableCell>
                            <TableCell>{pack.long}</TableCell>
                            <TableCell>{pack.weight}</TableCell>
                            <TableCell style={{ maxWidth: 80 }}>
                              <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                autoComplete="off"
                                defaultValue={pack.quantity ? pack.quantity : 1}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputProps: {
                                    min: 1,
                                  },
                                }}
                                onChange={(e) => {
                                  watchPackageType[i].quantity = Number(e.target.value);
                                  setValue('packages', watchPackageType);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* warehouseId */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="warehouseId"
                control={control}
                defaultValue={defaultSettings?.warehouseId}
                rules={{
                  required: 'Este campo es requerido',
                }}
                as={
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    variant="outlined"
                    label="Bodega origen"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.warehouseId ? true : false}
                    helperText={errors.warehouseId?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona bodega origen
                    </option>
                    {warehouses.map((warehouse) => (
                      <option value={warehouse.id} key={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {/* paymentType */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="paymentType"
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({ onChange, value }) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setValue('serviceLevel', '');
                    }}
                    variant="outlined"
                    label="Modalidad de pago"
                    SelectProps={{
                      native: true,
                    }}
                    error={errors.paymentType ? true : false}
                    helperText={errors.paymentType?.message}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="None" value="">
                      Selecciona modalidad de pago
                    </option>
                    {paymentTypesByUser.length > 0 &&
                      paymentTypes.length > 0 &&
                      optionsPaymentTypesByUser()}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>
          {/* insuredValue */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="outlined"
              margin="normal"
              name="insuredValue"
              fullWidth
              label="Valor asegurado (COP)"
              autoComplete="off"
              error={errors.insuredValue ? true : false}
              helperText={errors.insuredValue && errors.insuredValue.message}
              InputLabelProps={{ shrink: true }}
              inputRef={register<HTMLInputElement>({
                validate: (value) => (!value ? 'Este campo es requerido' : true),
              })}
              onBlur={() => handleOnBlur('insuredValue')}
            />
          </Grid>
          {/* serviceLevel */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <Controller
                name="serviceLevel"
                control={control}
                defaultValue={1}
                as={
                  <TextField
                    fullWidth
                    margin="normal"
                    select
                    variant="outlined"
                    label="Nivel de servicio"
                    SelectProps={{
                      native: true,
                    }}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option aria-label="Estándar" value={1}>
                      Estándar
                    </option>
                    <option aria-label="Recaudo contra entrega" value={26}>
                      Entrega mismo día
                    </option>
                  </TextField>
                }
              />
            </FormControl>
          </Grid>
          {/* buttons */}
          <Grid container justifyContent="center" spacing={2}>
            <Button
              type="submit"
              disabled={isLoading}
              variant="contained"
              color="primary"
              style={{ marginTop: 20 }}
              startIcon={isLoading ? <CircularProgress size={24} /> : ''}
            >
              Aceptar
            </Button>
            <Button
              variant="contained"
              color="default"
              disabled={isLoading}
              style={{ marginTop: 20, marginLeft: 20 }}
              onClick={closeModal}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalContainer>
  );
}
