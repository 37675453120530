import React, { ReactElement } from 'react';
import AdminTemplate from 'components/templates/AdminTemplate';

export default function Dashboard(): ReactElement {
  return (
    <AdminTemplate>
      <div>Dashboard</div>
    </AdminTemplate>
  );
}
