import React, { createContext, useMemo, useState } from 'react';
import { isEmail } from 'utils/validator';
import { convertErrorsApiToErrorsForm } from 'utils/handleErrors';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { URL_CREATE_ACCOUNT, URL_FRONT } from 'constants/url';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ACCESS_TOKEN_NAME, USER_KEY_STORE } from 'constants/securityConts';
import { NewUser, NewUserErrors } from 'interfaces/user.interface';

export const newUserDefault: NewUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  acceptTermsAndConditions: '',
};

export const newUserErrorsDefault: NewUserErrors = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  acceptTermsAndConditions: '',
  othersErrors: [],
};

interface RegisterUserContextReturn {
  createUser(newUser: NewUser): void;
  isValidUser(user: NewUser): boolean;
  newUserErrors: NewUserErrors;
  isLoading: boolean;
}

const defaultContext: RegisterUserContextReturn = {
  createUser: () => undefined,
  isValidUser: () => false,
  newUserErrors: newUserErrorsDefault,
  isLoading: false,
};

const RegisterUserContext = createContext<RegisterUserContextReturn>(defaultContext);

export function RegisterUserProvider(props: any) {
  const [newUserErrors, setNewUserErrors] = useState(newUserErrorsDefault);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isValidUser = (user: NewUser): boolean => {
    const errors: NewUserErrors = { ...newUserErrorsDefault };

    if (!user.firstName) {
      errors.firstName = 'Campo Nombres es obligatorio';
      errors.haveErrors = true;
    }

    if (!user.lastName) {
      errors.lastName = 'Campo Apellidos es obligatorio';
      errors.haveErrors = true;
    }

    if (!isEmail(user.email)) {
      errors.email = 'Email no valido';
      errors.haveErrors = true;
    }

    if (user.password.length < 6) {
      errors.password = 'Contraseña debe tener mínimo 6 caracteres';
      errors.haveErrors = true;
    }

    if (!user.acceptTermsAndConditions) {
      errors.acceptTermsAndConditions = 'Debes aceptar terminos y condiciones.';
      errors.haveErrors = true;
    }
    setNewUserErrors({ ...errors });

    if (errors.haveErrors) {
      return false;
    } else {
      return true;
    }
  };

  const createUser = async (user: NewUser) => {
    try {
      setIsLoading(true);
      const response: AxiosResponse = await Axios.post(`${URL_CREATE_ACCOUNT}`, user);
      if (response) {
        setIsLoading(false);
      }

      if (response.status === 201) {
        if (response.data?.accessToken) {
          console.log('response.data?.accessToken', response.data.accessToken);

          window.localStorage.setItem(ACCESS_TOKEN_NAME, response.data.accessToken);
          const userToSave = {
            id: response.data?.id,
            firstName: user.firstName,
            email: user.email,
            role: response.data.role,
            isConfigurationComplete: false,
          };
          window.localStorage.setItem(USER_KEY_STORE, JSON.stringify(userToSave));
        }

        // Success 🎉
        enqueueSnackbar('Cuenta creada exitosamente.', {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        });
        setTimeout(() => {
          window.location.href = `${URL_FRONT}/admin/settings`;
        }, 500);
      }
    } catch (error) {
      const err = error as AxiosError;
      setIsLoading(false);
      // Error 😨
      if (err.response) {
        /*
         * Cuando el status code de la respuesta del servidor no está en el rango de 2xx
         */
        setNewUserErrors(convertErrorsApiToErrorsForm(err.response.data, newUserErrorsDefault));
      } else if (err.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(err.request);
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', err.message);
      }
    }
  };

  const value = useMemo((): RegisterUserContextReturn => {
    return {
      createUser,
      isValidUser,
      newUserErrors,
      isLoading,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserErrors, isLoading]);

  return <RegisterUserContext.Provider value={value} {...props} />;
}

export function useRegisterContext() {
  const context = React.useContext(RegisterUserContext);
  if (!context) {
    throw new Error('useRegisterContext debe estar dentro del proveedor RegisterUserContext.');
  }
  return context;
}
