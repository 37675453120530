import React from 'react';
import { useSnackbar } from 'notistack';

// Material ui core
import IconButton from '@material-ui/core/IconButton';

// Material ui icons
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

interface IEnqueueOptions {
  /** Type of the snackbar */
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;
  message: string;
}

const styles = makeStyles(() => ({
  icon: {
    fontSize: 20,
  },
}));

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = styles();

  const action = (key: any) => (
    <>
      <IconButton
        color="inherit"
        aria-label="cerrar toast"
        onClick={() => {
          closeSnackbar(key);
        }}
        size="small"
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    </>
  );

  const showNotistack = ({ message, variant = 'default' }: IEnqueueOptions) => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      preventDuplicate: true,
      action,
    });
  };
  return { showNotistack };
};
export default useCustomSnackbar;
