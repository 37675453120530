import React, { ReactElement } from 'react';
import ConfigureShippingCarrierOrganism from 'components/organisms/ConfigureShippingCarrier';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
import Modal from 'components/organisms/ConfigureShippingCarrierModal';
import AdminTemplate from 'components/templates/AdminTemplate';

import useUser from 'hooks/useUser';

export default function ConfigureShippingCarrier(): ReactElement {
  const { user } = useUser();
  return (
    <AdminTemplate>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1" align="center">
            Configuración
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ConfigureShippingCarrierOrganism />
        </Grid>
      </Grid>
      {user?.isConfigurationComplete === false && <Modal />}
    </AdminTemplate>
  );
}
