import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './footer.styles';

export default function Footer(): ReactElement {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Typography variant="subtitle2">
        © 2020 COORDINADORA :: Todos los derechos reservados
      </Typography>
    </Grid>
  );
}
