import React, { ReactElement } from 'react';
import MuiTable from 'components/molecules/MuiTable';
import { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { Button } from '@material-ui/core';
import CustomToolbar from 'components/molecules/CustomToolBarMuiDatatable/CustomToolbarMuiDatatable';
import textLabels from 'utils/MUIDataTableTextLabels';
import { IPackage } from 'interfaces/shippingPackage.interface';
interface Props {
  packages: IPackage[];
  isLoading: boolean;
  addButton(): void;
  handleOnClickEditPackage(packageId: string): void;
  handleOnClickDeletePackage(packageId: string): void;
}

export default function PackagesList({
  packages,
  isLoading,
  addButton,
  handleOnClickEditPackage,
  handleOnClickDeletePackage,
}: Props): ReactElement {
  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: 'false',
      },
    },
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'height',
      label: 'Alto',
    },
    {
      name: 'width',
      label: 'Ancho',
    },
    {
      name: 'long',
      label: 'Largo',
    },
    {
      name: 'weight',
      label: 'Peso',
    },
    {
      name: '',
      label: 'Acciones',
      options: {
        customBodyRender: (value, tableMeta: MUIDataTableMeta) => {
          if (value) return value;
          return (
            <React.Fragment>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  handleOnClickEditPackage(tableMeta.rowData[0]);
                }}
              >
                Editar
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="default"
                onClick={() => {
                  handleOnClickDeletePackage(tableMeta.rowData[0]);
                }}
                style={{ marginLeft: 20 }}
              >
                Eliminar
              </Button>
            </React.Fragment>
          );
        },
      },
    },
  ];
  const options: MUIDataTableOptions = {
    textLabels,
    download: false,
    filter: false,
    print: false,
    selectableRowsHeader: false,
    selectableRows: 'none',
    viewColumns: false,
    customToolbar: () => {
      return <CustomToolbar tooltip="Agregar paquete" onClick={addButton} />;
    },
  };

  return (
    <div>
      <MuiTable
        title="Lista de paquetes"
        isLoading={isLoading}
        columns={columns}
        data={packages}
        options={options}
      />
    </div>
  );
}
