import React, { ReactElement } from 'react';
import logo from 'assets/img/logo.png';
import { Grid } from '@material-ui/core';

function Home(): ReactElement {
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ marginTop: '40px' }}
    >
      <img src={logo} alt="logo" />
    </Grid>
  );
}

export default Home;
