import { makeStyles, Theme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
const drawerWidth = 240;

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  listItems: {
    overflow: 'auto',
    '&$selected': {
      backgroundColor: 'red',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: 'purple',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#E3E3E3',
    },
    '&:selected': {
      backgroundColor: 'red',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 40,
    background: grey[300],
    minHeight: 650,
  },
}));
