import axios, { AxiosResponse } from 'axios';
import useUser from './useUser';

import { URL_SCHEDULE_PICKUP } from 'constants/url';

export default function useSchedulePickup() {
  const { jwt } = useUser();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const addSchedulePickup = (data: any): Promise<number> => {
    return axios.post(URL_SCHEDULE_PICKUP, data, { headers }).then((response: AxiosResponse) => {
      if (response.status === 200) {
        return response.data.id;
      } else {
        throw new Error('Respuesta inesperada');
      }
    });
  };

  return {
    addSchedulePickup,
  };
}
