import { URL_ORDERS } from 'constants/url';
import axios from 'axios';
import { IOrder, OrdersFiltersData, Pagination } from 'interfaces';
import { format } from 'date-fns';
interface GetAllOrders {
  jwt: string | null;
  pagination: Pagination;
  filters?: OrdersFiltersData;
}
export interface OrdersResponse {
  data: IOrder[];
  total: number;
  totalPages: number;
}
export function getAllOrders({ jwt, pagination, filters }: GetAllOrders): Promise<OrdersResponse> {
  const { limit, page } = pagination;
  return new Promise((resolve, reject) => {
    const filtersToSend: string[] = [];

    if (filters?.startDate && filters?.endDate) {
      filtersToSend.push(
        JSON.stringify({
          column: 'date',
          value: [
            format(filters?.startDate, 'yyyy-MM-dd HH:mm:ss'),
            format(filters?.endDate, 'yyyy-MM-dd HH:mm:ss'),
          ],
        })
      );
    }

    if (filters?.orderId) {
      filtersToSend.push(JSON.stringify({ column: 'id', value: [filters?.orderId] }));
    }

    if (filters?.trackingNumber) {
      filtersToSend.push(
        JSON.stringify({ column: 'trackingNumber', value: [filters?.trackingNumber] })
      );
    }

    axios
      .get<OrdersResponse>(
        `${URL_ORDERS}?page=${page}&limit=${limit}&filters=[${filtersToSend.join(',')}]`,
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject('Respuesta no esperada.');
        }
      })
      .catch((error) => {
        console.log(error);
        reject('Ocurrió un error, por favor contacta con soporte.');
      });
  });
}
