import useUser from './useUser';
import { AxiosResponse } from 'axios';
import axios from 'axios';
import { URL_PACKAGES } from 'constants/url';
import { IPackage } from 'interfaces/shippingPackage.interface';

export default function usePackage() {
  const { jwt } = useUser();

  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getPackageById = async (id: string): Promise<IPackage> => {
    return axios.get(`${URL_PACKAGES}/${id}`, { headers }).then((response: AxiosResponse) => {
      return response.data;
    });
  };

  const getPackages = async (): Promise<IPackage[]> => {
    return axios
      .get(URL_PACKAGES, { headers })
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  const addPackage = async (packageToAdd: IPackage): Promise<boolean> => {
    return axios
      .post(URL_PACKAGES, packageToAdd, { headers })
      .then((response: AxiosResponse): boolean => {
        return response.status === 201;
      });
  };

  const updatePackage = async (packageToAdd: IPackage): Promise<boolean> => {
    if (!packageToAdd.id) throw new Error('El campo id es obligatorio');

    return axios
      .put(`${URL_PACKAGES}/${packageToAdd.id}`, packageToAdd, { headers })
      .then((response: AxiosResponse): boolean => {
        return response.status === 204;
      });
  };

  const deletePackage = async (packageId: string): Promise<boolean> => {
    if (!packageId) throw new Error('El id es obligatorio');

    return axios
      .delete(`${URL_PACKAGES}/${packageId}`, { headers })
      .then((response: AxiosResponse): boolean => {
        return response.status === 204;
      });
  };

  return { getPackageById, getPackages, addPackage, updatePackage, deletePackage };
}
