import React, { ReactElement } from 'react';
import logo from 'assets/img/logo.png';
import useStyles from './loginForm.styles';
import { useForm } from 'react-hook-form';
import useUser from 'hooks/useUser';

import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ListOfErrors from 'components/molecules/Form/ListOfErrors';

interface IFormInput {
  email: string;
  password: string;
}

export default function LoginForm(): ReactElement {
  const { handleSubmit, register, errors } = useForm<IFormInput>();
  const { login, isLoading, hasLoginError } = useUser();
  const classes = useStyles();
  const onSubmit = async (data: IFormInput) => {
    login(data);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} className="App-logo" alt="logo" height="30" />
        <Typography component="h1" variant="h5" style={{ marginTop: 40 }}>
          Inicia sesión para continuar
        </Typography>
        {hasLoginError && <ListOfErrors errors={['El Email o la contraseña no son válidos.']} />}
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            disabled={isLoading}
            inputRef={register<HTMLInputElement>({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'El Email no es válido.',
              },
            })}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            inputRef={register<HTMLInputElement>({ required: true })}
            disabled={isLoading}
            error={errors.password ? true : false}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : ''}Aceptar
          </Button>
          <Grid container justifyContent="space-between">
            <Grid>
              <Link to="/register">Crear cuenta</Link>
            </Grid>

            <Grid>
              <Link to="/recover-password">Recupera contraseña</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
