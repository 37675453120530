import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import useUser from 'hooks/useUser';
import AdminTemplate from 'components/templates/AdminTemplate';
import { Button, Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useStyles from './connetStore.styles';
import connectImg from 'assets/img/undraw_server_down_s4lk.svg';
import errorImg from 'assets/img/faq_1.svg';
import {
  URL_WCCONFIGURATIONS,
  URL_WOOCOMMERCE_RETURN,
  URL_WOOCOMMERCE_CALLBACK,
} from 'constants/url';
import useCustomSnackbar from 'hooks/useCustomSnackbar';
import { validURL } from 'utils/validator';

const ConnectStore = () => {
  const { showNotistack } = useCustomSnackbar();
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const { user, jwt } = useUser();
  const [url, setUrl] = useState('');

  const success = params.get('success');
  useEffect(() => {
    if (success === '1') {
      history.push('/admin/orders');
    }
  }, []);

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let urlToRedirect = url;
      console.log('urlToRedirect.slice(0, -1)', urlToRedirect.slice(0, -1));

      if (urlToRedirect.slice(0, -1) === '/') {
        urlToRedirect = urlToRedirect.slice(0, -1);
      }

      console.log('urlToRedirect', urlToRedirect);
      if (!validURL(urlToRedirect)) {
        showNotistack({ message: 'Url no válida.' });
        return;
      }
      const response = await axios.post(
        URL_WCCONFIGURATIONS,
        {
          shopUrl: urlToRedirect,
        },
        { headers: { Authorization: `Bearer ${jwt}` } }
      );

      if (response.status === 201 && response.data?.urlToRedirect) {
        window.location.href = `${response.data?.urlToRedirect}/wc-auth/v1/authorize?app_name=Coordinadora&scope=read_write&user_id=${user?.id}&return_url=${URL_WOOCOMMERCE_RETURN}&callback_url=${URL_WOOCOMMERCE_CALLBACK}&shop_url=${url}`;
      } else {
        showNotistack({ message: 'Ocurrió un error, por favor contacte a soporte.' });
      }
    } catch (error) {
      showNotistack({ message: 'Ocurrió un error, por favor contacte a soporte.' });
    }
  };

  return (
    <AdminTemplate>
      {success === '0' ? (
        <>
          <Grid container justifyContent="center">
            <img src={errorImg} alt="No se pudo vincular tu tienda" width="30%" />
          </Grid>
          <Typography variant="body1" align="center" className={classes.textField}>
            Upss!! Ocurrió un error y no se pudo vincular tu tienda, por favor contacta con soporte.
          </Typography>
        </>
      ) : (
        <>
          <Grid container justifyContent="center">
            <img src={connectImg} alt="conecta con tu tienda" width="30%" />
          </Grid>
          <Typography variant="body1" align="center" className={classes.textField}>
            Conecta tu tienda con nosotros y comienza a generar las guías de tus pedidos de
            Woocommerce.
          </Typography>
          <Typography variant="body1" align="center">
            Por favor ingresa la url de tu tienda y presiona el botón de conectar para iniciar el
            proceso de vinculación.
          </Typography>
          <form onSubmit={handleOnSubmit}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6} md={5} className={classes.textField}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="url tienda (ejemplo https://mumblun.com)"
                  margin="dense"
                  onChange={(e) => setUrl(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={1} md={1} className={classes.textField}>
                <Button variant="outlined" color="primary" type="submit">
                  Conectar
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </AdminTemplate>
  );
};

export default ConnectStore;
