import Products from './Products';
import { ProductsContextProvider } from 'contextApi/ProductsContext';
import React from 'react';

export default function (props: any) {
  return (
    <ProductsContextProvider>
      <Products {...props} />
    </ProductsContextProvider>
  );
}
