import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LoginForm from 'components/organisms/LoginForm';
import useUser from 'hooks/useUser';
import { Typography } from '@material-ui/core';

export default function Login(): ReactElement {
  const history = useHistory();
  const { isLogged, user } = useUser();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const isDemo = params.get('_bt');
  console.log('code', code);

  useEffect(() => {
    if (isLogged && user?.isConfigurationComplete !== true) {
      history.push('/admin/settings');
    }
    if (isLogged && user?.isConfigurationComplete) {
      history.push('/admin/orders');
    }
  }, [isLogged]);
  return (
    <React.Fragment>
      <LoginForm />
      {isDemo && (
        <Typography variant="subtitle1" align="center" style={{ marginTop: 30 }}>
          Para propositos de demostración usar el usuario demo@demo.com y la contraseña demo123456
        </Typography>
      )}
    </React.Fragment>
  );
}
