import React, { ReactElement, FormEvent, useState } from 'react';
import {
  Typography,
  Container,
  CssBaseline,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { useRegisterContext, newUserDefault } from 'contextApi/RegisterContextApi';

import useStyles from './registerForm.styles';
import ListOfErrors from 'components/molecules/Form/ListOfErrors';
import { useLocation, useHistory } from 'react-router-dom';
import { NewUser } from 'interfaces/user.interface';

export default function RegisterForm(): ReactElement {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { newUserErrors, isValidUser, isLoading, createUser } = useRegisterContext();
  const [user, setUser] = useState<NewUser>({
    ...newUserDefault,
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidUser(user)) {
      createUser(user);
    }
  };

  const goToLoginPage = () => {
    history.push(`/login${location.search}`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container justifyContent="center">
          <Typography
            component="h2"
            variant="subtitle1"
            onClick={goToLoginPage}
            className={classes.link}
          >
            Tienes cuenta? Ingresa haciendo click aquí
          </Typography>
        </Grid>
        <Typography component="h2" variant="h6" style={{ marginTop: 20 }}>
          o
        </Typography>
        <Typography component="h2" variant="h6">
          Crea tu cuenta gratuita.
        </Typography>
        <ListOfErrors errors={newUserErrors.othersErrors} />
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nombres"
                value={user.firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUser({ ...user, firstName: e.target.value })
                }
                error={newUserErrors.firstName.length > 0}
                helperText={newUserErrors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellidos"
                name="lastName"
                autoComplete="lname"
                value={user.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUser({ ...user, lastName: e.target.value })
                }
                error={newUserErrors.lastName.length > 0}
                helperText={newUserErrors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={user.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUser({ ...user, email: e.target.value })
                }
                error={newUserErrors.email.length > 0}
                helperText={newUserErrors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={user.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUser({ ...user, password: e.target.value })
                }
                error={newUserErrors.password.length > 0}
                helperText={newUserErrors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <a
                href="https://www.coordinadora.com/aviso-de-privacidad"
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos y condiciones
              </a>
              <FormControl error={newUserErrors.acceptTermsAndConditions.length > 0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="yes"
                      color="primary"
                      checked={user.acceptTermsAndConditions === 'yes'}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setUser({
                          ...user,
                          acceptTermsAndConditions: event.target.checked ? 'yes' : '',
                        });
                      }}
                    />
                  }
                  label="Acepto términos y condiciones."
                />
                {newUserErrors.acceptTermsAndConditions.length > 0 && (
                  <FormHelperText>{newUserErrors.acceptTermsAndConditions}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Aceptar'}
          </Button>
        </form>
      </div>
    </Container>
  );
}
