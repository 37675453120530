import React, { useState } from 'react';
import { ACCESS_TOKEN_NAME, USER_KEY_STORE } from 'constants/securityConts';

type State = {
  jwt: string | null;
  setJwt(newJwt: string | null): void;
  user: User | null;
  setUser(newUser: User | null): void;
  shopName: string | null;
  setShopName(newShopName: string | null): void;
};
export type User = {
  id: string;
  firstName?: string;
  email: string;
  role: string;
  isConfigurationComplete: boolean;
};

interface Props {
  children: React.ReactNode;
}

const UserContext = React.createContext<State | undefined>(undefined);

export function UserContextProvider({ children }: Props) {
  const [jwt, setJwt] = useState(() => window.localStorage.getItem(ACCESS_TOKEN_NAME));
  const [shopName, setShopName] = useState(() => window.localStorage.getItem('shopName'));

  const [user, setUser] = useState<User | null>(() => {
    const userStored = window.localStorage.getItem(USER_KEY_STORE);
    if (userStored) {
      return JSON.parse(userStored);
    }
  });

  return (
    <UserContext.Provider value={{ jwt, setJwt, user, setUser, shopName, setShopName }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
