import axios, { AxiosResponse } from 'axios';
import useUser from './useUser';

import { URL_CITIES } from 'constants/url';
import { ICity } from 'interfaces/city.interface';

export default function useCities() {
  const { jwt } = useUser();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getCities = (): Promise<ICity[]> => {
    return axios.get(URL_CITIES, { headers }).then((result: AxiosResponse) => {
      return result.data;
    });
  };

  const getDepartaments = (cities: ICity[]): string[] => {
    const departamentsUniques: string[] = [];
    cities
      .map((city) => city.nombre_departamento)
      .filter((dep) => {
        if (departamentsUniques.indexOf(dep) === -1) {
          departamentsUniques.push(dep);
        }
      });

    return departamentsUniques;
  };

  const getCitiesByDepartament = (cities: ICity[], filterByDepartament: string): ICity[] => {
    return cities.filter(
      (cityToFilter) => cityToFilter.nombre_departamento === filterByDepartament
    );
  };

  return {
    getCities,
    getDepartaments,
    getCitiesByDepartament,
  };
}
