import React, { ReactElement, useState, useEffect } from 'react';

// Material ui core
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  Select,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

// Styles
import useStyles from './SchedulePickupForm.styles';

//SnackBar
import useCustomSnackbar from 'hooks/useCustomSnackbar';

// Fns
import dateFnsEsLocale from 'date-fns/locale/es';

// Datepicker
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { convertErrorsApiToErrorsForm } from 'utils/handleErrors';
import { ISchedulePickup } from 'interfaces/schedulePickup.interface';
import { IWarehousePickup } from 'interfaces/warehouse.interface';

interface Props {
  handleOnSubmit(data: ISchedulePickup): Promise<number>;
  warehouses: IWarehousePickup[];
}

export default function SchedulePickupForm({ handleOnSubmit, warehouses }: Props): ReactElement {
  const { handleSubmit, register, errors, reset } = useForm<ISchedulePickup>();

  const [isLoading, setIsLoading] = useState(false);

  const [collecDate, setCollecDate] = useState<string>('');

  const errorsForm = {
    collectedDate: '',
  };

  const classes = useStyles();

  const { showNotistack } = useCustomSnackbar();

  const onSubmit = async (data: ISchedulePickup) => {
    setIsLoading(true);
    handleOnSubmit(data)
      .then((idScheduleResponse) => {
        if (idScheduleResponse) {
          showNotistack({ message: `La solicitud se creó con el id: ${idScheduleResponse}` });
          reset(data, {
            dirtyFields: true,
            isDirty: true,
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
          });
        }
      })
      .catch((scheduleResponse) => {
        if (scheduleResponse.response.data.message) {
          showNotistack({ message: `${scheduleResponse.response.data.message}` });
        } else {
          showNotistack({ message: `Ocurrió un error al crear la solicitud` });
        }
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        className={classes.backgroundGrid}
      >
        <Grid item xs={4}>
          <FormControl fullWidth variant="outlined" className={classes.marginFields}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsEsLocale}>
              <DatePicker
                name="collectedDate"
                value={collecDate}
                label="Fecha de Recogida"
                format="yyyy-MM-dd"
                onChange={(date) => {
                  if (date != null) setCollecDate(date.toString());
                }}
                inputRef={register<HTMLInputElement>({
                  required: 'Este campo es requerido',
                })}
                inputVariant="outlined"
                minDate={new Date()}
                error={errors.collectedDate ? true : false}
                helperText={errors.collectedDate && errors.collectedDate.message}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.marginFields}
            error={errors.productId && errors.productId.message ? true : false}
          >
            <InputLabel htmlFor="Seleccione un producto">Seleccione un producto</InputLabel>
            <Select
              native
              label="Seleccione un producto"
              inputProps={{
                name: 'productId',
                id: 'productId',
              }}
              inputRef={register<HTMLInputElement>({
                required: 'Este campo es requerido',
              })}
              error={errors.productId ? true : false}
            >
              <option aria-label="None" value="" />
              <option value={4}>Mercancía</option>
              <option value={2}>Mensajería</option>
            </Select>
            <FormHelperText>
              {errors.productId && errors.productId.message ? 'Este campo es requerido' : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.marginFields}
            error={errors.warehouseId && errors.warehouseId.message ? true : false}
          >
            <InputLabel htmlFor="Seleccione una bodega">Seleccione una bodega</InputLabel>
            <Select
              native
              label="Seleccione una bodega"
              inputProps={{
                name: 'warehouseId',
                id: 'warehouseId',
              }}
              inputRef={register<HTMLInputElement>({
                required: 'Este campo es requerido',
              })}
              error={errors.warehouseId ? true : false}
            >
              <option aria-label="None" value="" />
              {warehouses.map((warehouse: IWarehousePickup, index: number) => (
                <option key={index} value={warehouse.id}>
                  {warehouse.name}
                </option>
              ))}
            </Select>
            <FormHelperText>
              {errors.warehouseId && errors.warehouseId.message ? 'Este campo es obligatorio' : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            margin="normal"
            id="authorizedPerson"
            label="Nombre de quien autoriza"
            name="authorizedPerson"
            autoComplete="authorizedPerson"
            fullWidth
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
            })}
            error={errors.authorizedPerson ? true : false}
            helperText={errors.authorizedPerson && errors.authorizedPerson.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            type="email"
            variant="outlined"
            margin="normal"
            fullWidth
            id="notificationDestination"
            label="Email de quien autoriza"
            name="notificationDestination"
            autoComplete="notificationDestination"
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'El Email no es válido.',
              },
            })}
            error={errors.notificationDestination ? true : false}
            helperText={errors.notificationDestination && errors.notificationDestination.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="phoneNumber"
            label="Teléfono de quien autoriza"
            name="phoneNumber"
            autoComplete="phoneNumber"
            inputRef={register<HTMLInputElement>({
              required: 'Este campo es requerido',
              pattern: {
                value: /^[0-9\b]+$/,
                message: 'Teléfono no valido',
              },
            })}
            error={errors.phoneNumber ? true : false}
            helperText={errors.phoneNumber && errors.phoneNumber.message}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid container justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            style={{ marginTop: 20 }}
          >
            {isLoading ? <CircularProgress size={24} /> : ''} Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
