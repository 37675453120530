import Orders from 'components/pages/Orders';
import OrderIcon from '@material-ui/icons/List';
import Products from 'components/pages/Products';
import ProductsIcon from '@material-ui/icons/ShoppingCart';
import SchedulePickup from 'components/pages/SchedulePickup';
import SchedulePickupIcon from '@material-ui/icons/LocalShipping';
import Warehouse from 'components/pages/Warehouse';
import WarehouseIcon from '@material-ui/icons/StoreMallDirectory';
import Packages from 'components/pages/Packages';
import PackagesIcon from '@material-ui/icons/Dashboard';
import Settings from 'components/pages/ConfigureShippingCarrier';
import SyncIcon from '@material-ui/icons/Sync';
import SettingsIcon from '@material-ui/icons/Settings';
import { IRoute } from 'interfaces/route.interface';

const cliente: IRoute[] = [
  {
    path: '/admin/orders',
    name: 'Pedidos',
    icon: OrderIcon,
    component: Orders,
  },
  {
    path: '/admin/products',
    name: 'Productos',
    icon: ProductsIcon,
    component: Products,
  },
  {
    path: '/admin/schedule',
    name: 'Programar recogida',
    icon: SchedulePickupIcon,
    component: SchedulePickup,
  },
  {
    path: '/admin/warehouse',
    name: 'Bodegas',
    icon: WarehouseIcon,
    component: Warehouse,
  },
  {
    path: '/admin/packages',
    name: 'Paquetes',
    icon: PackagesIcon,
    component: Packages,
  },
  {
    path: '/admin/connect-store',
    name: 'Vincular tienda',
    icon: SyncIcon,
    component: Settings,
  },
  {
    path: '/admin/settings',
    name: 'Configuración',
    icon: SettingsIcon,
    component: Settings,
  },
];
interface rout {
  [key: string]: IRoute[];
}
const routes: rout = {
  cliente,
};
export default routes;
