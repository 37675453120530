import { IOrder } from 'interfaces/order.interface';
import { Button, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import useShippingCarrier from 'hooks/useShippingCarrier';
import React, { useState } from 'react';

interface Props {
  ordersSelected: IOrder[];
  setOpenCreateMultipleShippingModal: (newValue: boolean) => void;
}

export default function OrdersCustomToolbarSelect({
  ordersSelected,
  setOpenCreateMultipleShippingModal,
}: Props) {
  const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);
  const [showCreateShippingBtn, setShowCreateShippingBtn] = useState(false);
  const [labelId, setLabelId] = useState<string>('');
  const [validateField, setValidateField] = useState({
    haveErrors: false,
    messageError: '',
  });

  const { generatePdfOfShippings } = useShippingCarrier();
  console.log('ordersSelected', ordersSelected);

  React.useEffect(() => {
    const someToCreateShipping = ordersSelected.some(
      (o) => !o.meta_data.some((m) => m.key === '_coordinadora_tracking_number' && m.value)
    );
    setShowCreateShippingBtn(someToCreateShipping);
    console.log('someToCreateShipping', someToCreateShipping);

    // Get tracking numbers.
    const trackingNumbersToSet = ordersSelected.reduce((acc: string[], { meta_data }) => {
      const trackingNumber = meta_data.find(({ key }) => key === '_coordinadora_tracking_number');
      if (trackingNumber && trackingNumber.value) acc.push(trackingNumber.value);

      return acc;
    }, []);

    // Update tracking numbers.
    setTrackingNumbers(trackingNumbersToSet);
  }, [ordersSelected]);

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
      {showCreateShippingBtn && (
        <Grid item xs={12} sm="auto" style={{ marginRight: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateMultipleShippingModal(true)}
          >
            Crear guías
          </Button>
        </Grid>
      )}

      {trackingNumbers.length > 0 && (
        <Grid item xs={12} sm={5}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                select
                name="labelId"
                margin="dense"
                variant="outlined"
                label="Formato del rótulo"
                SelectProps={{ native: true }}
                error={validateField.haveErrors}
                onChange={({ target: { value } }) => {
                  setLabelId(value);
                  if (value.length > 0) {
                    setValidateField({
                      haveErrors: false,
                      messageError: '',
                    });
                  }
                }}
                helperText={validateField?.messageError}
                InputLabelProps={{ shrink: true }}
              >
                <option aria-label="None" value="">
                  Selecciona formato del rótulo
                </option>
                <option aria-label="44" value="44">
                  Genérico
                </option>
                <option aria-label="55" value="55">
                  Genérico 10 x 10
                </option>
                <option aria-label="57" value="57">
                  Genérico tercio de página
                </option>
                <option aria-label="58" value="58">
                  Genérico media página
                </option>
                <option aria-label="59" value="59">
                  Genérico cuarto de página
                </option>
              </TextField>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Tooltip title="Descargar">
                <IconButton
                  color="inherit"
                  onClick={() => {
                    if (labelId === '') {
                      setValidateField({
                        haveErrors: true,
                        messageError: 'Campo obligatorio',
                      });
                      return;
                    }

                    generatePdfOfShippings(trackingNumbers, labelId);
                  }}
                  style={{ margin: '0 15px' }}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
