import axios, { AxiosResponse } from 'axios';
import {
  URL_CONFIGURE_SHIPPING_CARRIER,
  URL_SHIPPING_CARRIER_VALIDATE_CONFIGURATION,
  URL_GENERATE_PDF_TRACKING_NUMBERS,
  URL_SHIPPING,
} from 'constants/url';
import useUser from 'hooks/useUser';
import { downloadPDF } from 'utils/downloadPDF';
import { IConfiguration } from 'interfaces/shippingCarrier.interface';
import { IShippingRequest, IShippingResponse } from 'interfaces/shipping.interface';

export async function getRate() {
  return axios.get(URL_CONFIGURE_SHIPPING_CARRIER).then((result: AxiosResponse) => {
    return result.data;
  });
}

export const isConfigurationComplete = (): Promise<boolean> =>
  new Promise((resolve) => resolve(false));

export default function useShippingCarrier() {
  const { jwt, setIsConfigurationComplete } = useUser();

  async function getConfiguration(): Promise<IConfiguration> {
    return axios
      .get(URL_CONFIGURE_SHIPPING_CARRIER, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((result: AxiosResponse) => {
        return result.data;
      });
  }

  async function getDefaultConfiguration() {
    return axios
      .get(`${URL_CONFIGURE_SHIPPING_CARRIER}/settings`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((result: AxiosResponse) => {
        return result.data;
      });
  }

  async function saveConfiguration(data: IConfiguration): Promise<boolean> {
    return axios
      .put(`${URL_CONFIGURE_SHIPPING_CARRIER}/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(() => {
        setIsConfigurationComplete(true);
        return true;
      });
  }

  async function isConfigurationComplete(): Promise<boolean> {
    return axios
      .get(URL_SHIPPING_CARRIER_VALIDATE_CONFIGURATION, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((result: AxiosResponse) => {
        return result.data?.isDone;
      });
  }

  async function createTrackingId(data: IShippingRequest[]): Promise<IShippingResponse> {
    return new Promise((resolve, reject) => {
      axios
        .post<IShippingResponse>(`${URL_SHIPPING}`, data, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          if (response.status === 201 && response.data.trackingId) {
            resolve(response.data);
          } else {
            reject('Respuesta no esperada');
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function generatePdfOfShippings(
    trackingNumbers: string[],
    labelId: string
  ): Promise<string> {
    const queryString = Object.keys(trackingNumbers)
      .map((key: string, index: number) => 'trackingNumbers=' + trackingNumbers[index])
      .join('&');
    return axios
      .get(`${URL_GENERATE_PDF_TRACKING_NUMBERS}?${queryString}&labelId=${labelId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response: AxiosResponse) => {
        downloadPDF(response.data.pdf, 'reImpresión_de_guias.pdf');
        return response.data;
      });
  }

  return {
    getConfiguration,
    getDefaultConfiguration,
    saveConfiguration,
    isConfigurationComplete,
    createTrackingId,
    generatePdfOfShippings,
  };
}
