import axios, { AxiosResponse } from 'axios';
import { URL_LOGIN } from 'constants/url';

type Login = {
  email: string;
  password: string;
};

type LoginResponse = {
  id: string;
  accessToken: string;
  email: string;
  firstName: string;
  role: string;
  isConfigurationComplete: boolean;
  shopName: string;
};

export default async function loginService(data: Login): Promise<LoginResponse | null> {
  const result: AxiosResponse = await axios.post(URL_LOGIN, {
    username: data.email,
    password: data.password,
  });
  if (result.status === 200 && result.data) {
    const loginResult: LoginResponse = { ...result.data };
    // guardar access_token
    return loginResult;
  } else {
    return null;
  }
}
