import React, { useState } from 'react';
import { Pagination } from 'interfaces/pagination.interface';

const defaultPagination: Pagination = {
  limit: 20,
  page: 0,
};

type State = {
  updatePagination(state: Pagination): void;
  pagination: Pagination;
};

interface Props {
  children: React.ReactNode;
}

const ProductsContext = React.createContext<State | undefined>(undefined);

export function ProductsContextProvider({ children }: Props) {
  const [pagination, setPagination] = useState<Pagination>(defaultPagination);

  const updatePagination = (state: Pagination) => {
    setPagination((oldState) => ({ ...oldState, ...state }));
  };

  return (
    <ProductsContext.Provider
      value={{
        updatePagination,
        pagination,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export const useProductsContext = () => {
  const context = React.useContext(ProductsContext);
  if (!context) {
    throw new Error(
      'useProductsContext debe estar dentro del proveedor dentro de ProductsContextProvider'
    );
  }
  return context;
};
